import { DateOrString } from './full-document-revision-object.types';
import { JAAS_APP } from './jaas-app.types';

export enum GROUP_LISTING_VIEW {
  FULL = 'FULL',
  STATS = 'STATS',
  SPECIFIC_GROUPS = 'SPECIFIC_GROUPS',
  SIMPLIFIED = 'SIMPLIFIED',
}

export interface MyjbiGroup {
  id: number;
  name: string;
  path: string;
  nlevel: number;
  maxGroupSize: number;
  createdAt: DateOrString;
  updatedAt: DateOrString;
  deletedAt: DateOrString | null;
  memberCount: number;
  userHasAccess?: boolean;
}

export interface PaginatedGroupResponse {
  items: MyjbiGroup[];
  itemCount: number;
  totalItems: number;
  pageCount: number;
  previous: string | null;
  next: string | null;
}

export enum GroupSortColumns {
  NAME = 'name',
  MEMBER_COUNT = 'memberCount',
  GROUP_TYPE = 'groupType',
}

export interface MyjbiGroupStat {
  id: number;
  name: string;
  path: string;
  nlevel: number;
  memberCount: number;
  licenseName?: string;
}

export interface MyjbiGroupMember {
  userId: number;
  attributes: {
    [s: string]: string;
  };
  isGroupAdmin?: boolean;
  isActiveUser?: boolean;
  username?: string;
  emailVerified?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface MyjbiGroupMemberPagination {
  items: MyjbiGroupMember[];
  itemCount: number;
  totalItems: number;
  pageCount: number;
}

export interface MyjbiGroupAttributeType {
  id: number;
  name: string;
  slug: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface MyjbiGroupAttributeSpec {
  required: boolean;
  attributeType: MyjbiGroupAttributeType;
}

export interface MyjbiGroupUserAttribute {
  id: number;
  name: string;
  slug: string;
  option: GroupUserAttributeOption | null;
  status: GroupUserAttributeTypeStatus;
  groupUserAttributeType: GroupUserAttributeType;
  groupUserAttributeValue?: MyjbiGroupUserAttributeValue[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface MyjbiGroupUserAttributeValue {
  id: number;
  value: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface MyjbiGroupUserAttributeSpec {
  groupUserAttribute: MyjbiGroupUserAttribute;
  required: boolean;
  isDefault?: boolean;
  lockAttribute?: boolean;
  group?: MyjbiGroup;
}

export interface MyjbiGroupUserAttributeList {
  id: number;
  name: string;
  slug: string;
  type: string;
  groupCount: number;
}

interface MyjbiGroupAgreement {
  id: number;
  fileUri: string;
  fileName: string;
  fileUrl: string;
  createdAt: Date;
}

interface MyJbiGroupEmailDomains {
  id: number;
  domain: string[];
  createdAt: Date;
  updatedAt: Date;
  groupId: number;
}

export interface MyjbiGroupDetail extends MyjbiGroup {
  id: number;
  name: string;
  path: string;
  nlevel: number;
  maxGroupSize: number;
  createdAt: DateOrString;
  updatedAt: DateOrString;
  deletedAt: DateOrString | null;
  memberCount: number;
  subGroups: MyjbiGroup[];
  ancestorGroups: MyjbiGroup[];
  members?: MyjbiGroupMember[];
  attributeSpecs: MyjbiGroupAttributeSpec[];
  attributeSpec?: MyjbiGroupUserAttributeSpec[];
  groupUserAttributeSpecs?: MyjbiGroupUserAttributeSpec[];
  agreements: MyjbiGroupAgreement[];
  types: string[];
  license: {
    id: number;
    name: string;
    startAt: DateOrString;
    endAt: DateOrString;
    seats: number;
    isActive: boolean;
    applicationId: JAAS_APP;
    groupProduct: {
      id: number;
      name: string;
    };
  };
  emailDomains?: string[];
  protectedTypes?: ProtectedTypes;
}

interface ProtectedTypes {
  isProtected: boolean;
  protectedType: ProtectedGroupTypes | null;
}

interface ProtectedGroupTypes {
  id: number;
  name: string;
  slug: string;
}

export interface GroupUserAttributeOption {
  value: string[];
  placeholder: string;
  status: GroupUserAttributeTypeStatus;
  multiSelect: boolean;
}

export interface GroupUserAttributeType {
  id: number;
  type: string;
  option: GroupUserAttributeOption[] | null;
  status: GroupUserAttributeTypeStatus;
}

export enum GroupUserAttributeTypeStatus {
  'DELETE' = 'DELETE',
  'ACTIVE' = 'ACTIVE',
}

export enum MyjbiGroupType {
  JBI = 'JBI',
  JBIC = 'JBIC',
  WoltersKluwerUsers = 'Wolters Kluwer',
}
