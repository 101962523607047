import { MutationTree } from 'vuex';
import { ContentItem, ContentState } from './content.state';

export enum ContentMutations {
  SET_SHORT_COURSES = 'SET_SHORT_COURSES',
  SET_EBP_RESOURCES = 'SET_EBP_RESOURCES',
  SET_NEWS = 'SET_NEWS',
  SET_NEWS_BUZZ = 'SET_NEWS_BUZZ',
  SET_SHORT_COURSES_LOADING = 'SET_SHORT_COURSES_LOADING',
  SET_EBP_RESOURCES_LOADING = 'SET_EBP_RESOURCES_LOADING',
  SET_NEWS_LOADING = 'SET_NEWS_LOADING',
  SET_NEWS_BUZZ_LOADING = 'SET_NEWS_BUZZ_LOADING',
  SET_SHORT_COURSES_ERROR = 'SET_SHORT_COURSES_ERROR',
  SET_EBP_RESOURCES_ERROR = 'SET_EBP_RESOURCES_ERROR',
  SET_NEWS_ERROR = 'SET_NEWS_ERROR',
  SET_NEWS_BUZZ_ERROR = 'SET_NEWS_BUZZ_ERROR'
}

export const mutations: MutationTree<ContentState> = {
  [ContentMutations.SET_SHORT_COURSES](state, items: ContentItem[]) {
    state.shortCourses = items;
  },
  [ContentMutations.SET_EBP_RESOURCES](state, items: ContentItem[]) {
    state.ebpResources = items;
  },
  [ContentMutations.SET_NEWS](state, items: ContentItem[]) {
    state.news = items;
  },
  [ContentMutations.SET_NEWS_BUZZ](state, items: ContentItem[]) {
    state.newsBuzz = items;
  },
  [ContentMutations.SET_SHORT_COURSES_LOADING](state, loading: boolean) {
    state.apiState.shortCourses.loading = loading;
  },
  [ContentMutations.SET_EBP_RESOURCES_LOADING](state, loading: boolean) {
    state.apiState.ebpResources.loading = loading;
  },
  [ContentMutations.SET_NEWS_LOADING](state, loading: boolean) {
    state.apiState.news.loading = loading;
  },
  [ContentMutations.SET_NEWS_BUZZ_LOADING](state, loading: boolean) {
    state.apiState.newsBuzz.loading = loading;
  },
  [ContentMutations.SET_SHORT_COURSES_ERROR](state, error: boolean) {
    state.apiState.shortCourses.error = error;
  },
  [ContentMutations.SET_EBP_RESOURCES_ERROR](state, error: boolean) {
    state.apiState.ebpResources.error = error;
  },
  [ContentMutations.SET_NEWS_ERROR](state, error: boolean) {
    state.apiState.news.error = error;
  },
  [ContentMutations.SET_NEWS_BUZZ_ERROR](state, error: boolean) {
    state.apiState.newsBuzz.error = error;
  }
};
