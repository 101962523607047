import { RootState } from '@/store/store';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { UserGroupsMutations } from './user-groups.mutations';
import { UserGroupsState } from './user-groups.state';

export const actions: ActionTree<UserGroupsState, RootState> = {
  async getUserGroups({ commit }) {
    commit(UserGroupsMutations.SET_LOADING, true);
    commit(UserGroupsMutations.SET_ERROR, false);

    try {
      const response = await Vue.prototype.axios.get('/me/user-groups');
      commit(UserGroupsMutations.SET_USER_GROUPS, response.data);
    } catch (error) {
      commit(UserGroupsMutations.SET_ERROR, true);
    } finally {
      commit(UserGroupsMutations.SET_LOADING, false);
    }
  }
};
