import { RootState } from '@/store/store';
import { Module } from 'vuex';
import { actions } from './events.actions';
import { mutations } from './events.mutations';
import { EventsState, state } from './events.state';

const namespaced = true;

export const events: Module<EventsState, RootState> = {
  namespaced,
  state,
  mutations,
  actions
};
