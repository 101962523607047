import {
  EntitiesVersusModules,
  ModulePermissionTypes,
  PermissionsForModule
} from '@/jbi-shared/types/entities-versus-modules.interface';
import { UserPermissions } from '@/jbi-shared/types/user-permissions.interface';

/**
 * This function populates the users array with dummy data in cases where
 * the number of users being returned don't match the limit. It was
 * implemented to avoid having to do additional work in frontend.
 *
 * PermissionsForEntityComponent.vue component is composed of nested tables.
 * Getting the widths of the table cells in the child tables with that of
 * the parent table proved to be especially tricky. While it would be
 * possible to design the component in such as a way that it can adjust
 * itself for situations where the number of cells in the children don't
 * match the number of cells in the parent, it's far easier to simply
 * return an array with dummy data instead.
 *
 * This is why this function exists, to serve as a lazy(?) solution to
 * a UI issue.
 * @param users
 * @param limit
 * @private
 */
export function populateEntitiesWithDummyData(
  users: UserPermissions[] | Array<{ id: number; name: string }>,
  limit = 10
): EntitiesVersusModules['users'] {
  if (!users) {
    return [];
  }
  /**
   * Creating new users array to avoid changing data of an existing object.
   * Otherwise, the function would cease to be pure and transform an
   * existing object in the heap.
   */
  const usersNewRef = JSON.parse(JSON.stringify(users));
  if (users.length < limit) {
    for (let i = 0; i < limit - users.length; i++) {
      usersNewRef.push({
        id: -1, // Represents a dummy user
        email: '',
        firstName: '',
        lastName: '',
        isSuperAdmin: false
      });
    }
  }

  return usersNewRef;
}

/**
 * Unlike populateEntitiesWithDummyData(), this function is not pure - it
 * deliberately transforms the argument. It is also recursive in nature as
 * permissions of submodules need to be transformed as well. That said, it is
 * ultimately doing the same thing as populateEntitiesWithDummyData - populating
 * the permissions array with dummy data to avoid having to account for missing
 * table cells in the `ModuleWithPermissions.vue` component.
 * @param permissionsForModules
 * @param limit
 */
export function populatePermissionsWithDummyData(
  permissionsForModules: PermissionsForModule[],
  limit = 10
): PermissionsForModule[] {
  if (!permissionsForModules) {
    return [];
  }

  for (const item of permissionsForModules) {
    if (item.permissions.length < limit) {
      const numberOfMissingPermissions = limit - item.permissions.length;
      // Nested for loop but it is bounded
      for (let j = 0; j < numberOfMissingPermissions; j++) {
        item.permissions.push(ModulePermissionTypes.NONE);
      }

      if (item.submodules.length > 0) {
        populatePermissionsWithDummyData(item.submodules, limit);
      }
    }
  }

  return permissionsForModules;
}
