import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import Vue from 'vue';
import Router from 'vue-router';
import { NavLink } from './jbi-shared/types/Nav';
import {
  CasbinRouteGuard,
  GeneralRouteGuard,
  UserAttributeRouteGuard
} from './router.guard';

Vue.use(Router);

/**
 * Routes are being lazy loaded meaning there's route level
 * code splitting. This tells webpack to generate a
 * separate chunk, about.[hash].js, for example for the
 * route 'about'.
 */
const router = new Router({
  linkExactActiveClass: 'exact-active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'non_module'
      },
      component: () => import('./views/LandingPage/LandingPage.vue')
    },
    {
      path: '/products',
      name: 'products',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'non_module'
      },
      component: () => import('./views/Home/HomePage.vue')
    },
    {
      path: '/account',
      name: 'UserProfile',
      meta: {
        authRequired: true,
        module: 'account'
      },
      component: () => import('./views/Profile/UserProfile.vue')
    },
    {
      path: '/orders',
      name: 'Orders',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'my_orders'
      },
      component: () => import('./views/Order/MyOrdersPage.vue')
    },
    {
      path: '/orders/:id',
      name: 'Order',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'my_orders'
      },
      component: () => import('./views/Order/OrderPage.vue')
    },
    {
      path: '/application/:appId/products',
      name: 'Products',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'products'
      },
      component: () => import('./views/Product/ProductPage.vue')
    },
    {
      path: '/checkout/:productId',
      name: 'Checkout',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'products'
      },
      component: () => import('./views/Product/ProductCheckoutPage.vue')
    },
    {
      path: '/admin/product-management',
      name: 'admin-product-management',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'product_administration'
      },
      component: () =>
        import('./views/ProductManagement/ProductManagementPage.vue')
    },
    {
      path: '/admin/third-party-management',
      name: 'third-party-management',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'third_party_access_management'
      },
      component: () =>
        import(
          './views/AdminThirdPartyManagement/AdminThirdPartyManagement.vue'
        )
    },
    {
      path: '/journals',
      name: 'Journals',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'journals'
      },
      component: () => import('./views/Journal/JournalPage.vue')
    },
    {
      path: '/admin/roles-and-permissions',
      name: 'roles-and-permissions',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'roles_and_permissions'
      },
      component: () =>
        import('./views/RolesAndPermissions/RolesAndPermissions.vue')
    },
    {
      path: '/admin/roles/:roleId',
      name: 'view-role-permissions',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'roles_and_permissions'
      },
      component: () => import('./views/RolesAndPermissions/RolePermissions.vue')
    },
    {
      path: '/admin/roles/:roleId/assigned',
      name: 'view-resources-assigned',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'roles_and_permissions-view_assigned_resources'
      },
      component: () => import('./views/RolesAndPermissions/RoleResources.vue')
    },
    {
      path: '/admin/exceptions/:moduleName',
      name: 'view-module-exceptions',
      meta: {
        authRequired: false,
        checkUserAttribute: true,
        module: 'roles_and_permissions'
      },
      component: () =>
        import('./views/RolesAndPermissions/ViewModuleExceptions.vue')
    },
    {
      path: '/admin/user-management',
      name: 'admin-user-management',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'user_administration'
      },
      component: () =>
        import('./views/AdminUserManagement/AdminUserManagement.vue')
    },
    {
      path: '/admin/tnc-management',
      name: 'terms-and-conditiions-management',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'terms_and_conditions'
      },
      component: () =>
        import(
          './views/TermsAndConditionsManagement/TermsAndConditionsManagement.vue'
        )
    },
    {
      path: '/admin/group-management',
      name: 'admin-group-management',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'group_administration',
        instanceType: EntityTypes.GROUP
      },
      component: () =>
        import('./views/AdminGroupManagement/AdminGroupManagement.vue')
    },
    {
      path: '/admin/create-group',
      name: 'admin-create-group',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'group_administration'
      },
      component: () => import('./views/AdminCreateGroup/AdminCreateGroup.vue')
    },
    {
      path: '/admin/group/:groupId',
      name: 'admin-view-group',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'group_administration',
        instanceType: EntityTypes.GROUP
      },
      component: () => import('./views/AdminViewGroup/AdminViewGroup.vue')
    },
    {
      path: '/admin/group/:groupId/settings',
      name: 'admin-group-settings',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'group_administration',
        instanceType: EntityTypes.GROUP
      },
      component: () =>
        import('./views/AdminGroupSettings/AdminGroupSettings.vue')
    },
    {
      path: '/admin/create-subgroup/:parentGroupId/:isProtected',
      name: 'admin-create-subgroup',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'group_administration',
        instanceType: EntityTypes.GROUP
      },
      component: () =>
        import('./views/AdminCreateSubGroup/AdminCreateSubGroup.vue')
    },
    {
      path: '/admin/transactions',
      name: 'admin-audit-log',
      meta: {
        authRequired: true,
        checkUserAttribute: true,
        module: 'transactions'
      },
      component: () => import('./views/AdminAuditLog/AdminAuditLog.vue')
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        module: 'non_module'
      },
      component: () => import('./views/About.vue')
    },
    {
      path: '/access-forbidden',
      name: 'access-forbidden',
      meta: {
        module: 'non_module'
      },
      component: () => import('./views/AccessForbidden.vue')
    },
    {
      path: '*',
      name: 'page-not-found',
      meta: {
        module: 'non_module'
      },
      component: () => import('./views/PageNotFound.vue')
    }
  ]
});

export const navLinks: NavLink[] = [
  {
    label: 'User Administration',
    to: {
      name: 'admin-user-management',
      module: 'user_administration',
      query: { tab: 'Users' }
    }
  },
  {
    label: 'Roles and Permissions',
    to: {
      name: 'roles-and-permissions',
      module: 'roles_and_permissions',
      query: { tab: 'Roles' }
    }
  },
  {
    label: 'Third Party Management',
    to: {
      name: 'third-party-management',
      module: 'third_party_access_management'
    }
  },
  {
    label: 'Product Administration',
    to: {
      name: 'admin-product-management',
      module: 'product_administration'
    }
  },
  {
    label: 'Terms & Conditions',
    to: {
      name: 'terms-and-conditiions-management',
      module: 'terms_and_conditions'
    }
  },
  {
    label: 'Group Administration',
    to: {
      name: 'admin-group-management',
      module: 'group_administration',
      query: { tab: 'Groups' },
      instanceType: EntityTypes.GROUP
    }
  },
  {
    label: 'Transactions',
    to: {
      name: 'admin-audit-log',
      module: 'transactions'
    }
  },
  {
    label: 'Account',
    to: {
      name: 'UserProfile',
      query: { tab: 'profile' },
      module: 'account'
    }
  },
  {
    label: 'Sign Out',
    url: `${process.env.VUE_APP_BACKEND_HOSTNAME}/logout`,
    class: 'has-text-primary has-text-weight-semibold'
  }
];

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-background');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach(GeneralRouteGuard);
router.beforeEach(UserAttributeRouteGuard);
router.beforeEach(CasbinRouteGuard);

export default router;
