import { GenericSuccessMessage } from '@/jbi-shared/interfaces/generic-success-message.interface';
import { AccessCondition } from '@/jbi-shared/types/access-condition.interface';
import { EntitiesWithPermissionsMatrix } from '@/jbi-shared/types/entities-with-permissions-matrix.interface';
import { MyjbiGroup } from '@/jbi-shared/types/myjbi-group.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import {
  AddGroupsToRoleDto,
  AddUsersToRoleDto,
  CreateExceptionsPayload,
  DeleteExceptionsPayload,
  GetPermissionsResponse,
  ModuleExceptionsResponse,
  ModuleTreeRecord,
  PermissionHelperPayload,
  PermissionHelperRolesAndExceptionPayload,
  ResourceExceptionDto,
  ResourceExceptions,
  Role,
  RolesAndExceptionsResponse,
  UpdateExceptionPayload
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { GeneralQueryParams } from '@/store/types/general.types';
import axios, { AxiosPromise, AxiosResponse } from 'axios';

export const rolesAndPermissionsApi = {
  async getModules(
    moduleName?: string
  ): Promise<AxiosResponse<ModuleTreeRecord[]>> {
    return axios.get('/module-tree/modules', { params: { moduleName } });
  },

  async getPermissionsForResourceByModule(
    payload: PermissionHelperPayload
  ): Promise<AxiosResponse<GetPermissionsResponse>> {
    const { moduleName, ...params } = payload;
    return axios.get(`permissions/${moduleName}`, { params });
  },

  async getRolesAndExceptionsForResourceByModule(
    payload: PermissionHelperRolesAndExceptionPayload
  ): Promise<AxiosResponse<RolesAndExceptionsResponse>> {
    const { moduleName, ...params } = payload;
    return axios.get(`permissions/roles-and-permissions/${moduleName}`, {
      params
    });
  },

  async addUsersToRole(
    id: number,
    payload: AddUsersToRoleDto
  ): Promise<AxiosResponse<GenericSuccessMessage>> {
    return axios.post(`roles/${id}/users`, payload);
  },

  async addGroupsToRole(
    id: number,
    payload: AddGroupsToRoleDto
  ): Promise<AxiosResponse<GenericSuccessMessage>> {
    return axios.post(`roles/${id}/groups`, payload);
  },

  async getExceptionsByModule(
    moduleName: string
  ): Promise<AxiosPromise<ModuleExceptionsResponse>> {
    return axios.get(`exceptions/${moduleName}`);
  },

  async getResourceExceptionsByModule(payload: {
    moduleName: string;
    resourceId: string;
    instanceId?: string;
    instanceType?: EntityTypes;
  }): Promise<AxiosPromise<Role>> {
    return axios.get(
      `exceptions/${payload.moduleName}/resource/${payload.resourceId}`,
      {
        params: {
          instanceId: payload.instanceId,
          instanceType: payload.instanceType
        }
      }
    );
  },

  async getResourceInstancesExceptions(
    resourceId: string
  ): Promise<AxiosResponse<ResourceExceptionDto>> {
    return axios.get(`exceptions/instances/resource/${resourceId}`);
  },

  async getGroupExceptions(
    groupId: number
  ): Promise<AxiosResponse<ResourceExceptions[]>> {
    return axios.get(`exceptions/groups/${groupId}`);
  },

  async createException(
    payload: CreateExceptionsPayload
  ): Promise<AxiosResponse<GenericSuccessMessage>> {
    return axios.post(`exceptions`, payload);
  },

  async updateException(
    payload: UpdateExceptionPayload
  ): Promise<AxiosResponse<GenericSuccessMessage>> {
    return axios.put(`exceptions`, payload);
  },

  async deleteException(
    payload: DeleteExceptionsPayload
  ): Promise<AxiosResponse<GenericSuccessMessage>> {
    return axios.delete(
      `exceptions/delete/${payload.module}/resource/${payload.resourceId}`
    );
  },

  async getPermissionsForUsers(
    payload: GeneralQueryParams
  ): Promise<AxiosResponse<EntitiesWithPermissionsMatrix>> {
    return axios.get(`permissions/users`, {
      params: {
        page: payload.page,
        limit: payload.limit,
        sortOrder: payload.sortOrder,
        searchQuery: payload.searchQuery
      }
    });
  },

  async getPermissionsForGroups(
    payload: GeneralQueryParams
  ): Promise<AxiosResponse<EntitiesWithPermissionsMatrix>> {
    return axios.get(`permissions/groups`, { params: payload });
  },

  async getAccessConditionsForEntity(
    moduleName: string,
    entityId: string
  ): Promise<AxiosResponse<AccessCondition>> {
    return axios.get(`permissions/access-condition/${entityId}/${moduleName}`);
  },

  async getFilteredPermissionsGroupByUserId(
    userId: number
  ): Promise<AxiosResponse<MyjbiGroup[]>> {
    return axios.get(`/permissions/users/${userId}/filtered-permissions`);
  }
};
