import { AccessCondition } from '@/jbi-shared/types/access-condition.interface';
import { EntitiesWithPermissionsMatrix } from '@/jbi-shared/types/entities-with-permissions-matrix.interface';
import { defaultApiState } from '@/store/types/general.types';
import {
  populateEntitiesWithDummyData,
  populatePermissionsWithDummyData
} from '@/utils/dummy-data.util';
import { AxiosError } from 'axios';
import {
  FilteredPermissionsGroupByUserIdResponse,
  GetPermissionsResponse,
  ModuleExceptionsResponse,
  ModuleTreeRecord,
  ResourceExceptionDto,
  ResourceExceptions,
  Role,
  RolesAndExceptionsResponse,
  RolesAndPermissionsState,
  SelectedGroupsForVueTreeselect
} from './types/roles-and-permissions.types';

export const rolesAndPermissionsMutations = {
  /** Get List of all modules */
  GET_MODULES_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getModules = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_MODULES_SUCCESS(
    state: RolesAndPermissionsState,
    payload: ModuleTreeRecord[]
  ) {
    state.apiState.getModules = {
      ...defaultApiState,
      success: true
    };
    state.modules = payload;
  },
  GET_MODULES_ERROR(state: RolesAndPermissionsState, error: AxiosError) {
    state.apiState.getModules = {
      ...defaultApiState,
      error
    };
  },

  /** Get permissions of the resource */
  GET_PERMISSIONS_FOR_RESOURCE_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getPermissionsForResource = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_PERMISSIONS_FOR_RESOURCE_SUCCESS(
    state: RolesAndPermissionsState,
    payload: GetPermissionsResponse
  ) {
    state.apiState.getPermissionsForResource = {
      ...defaultApiState,
      success: true
    };
    state.permissionsForResource = payload;
  },
  GET_PERMISSIONS_FOR_RESOURCE_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getPermissionsForResource = {
      ...defaultApiState,
      error
    };
  },

  /** Get roles an dexception of resource based on modile and instance */
  GET_ROLES_AND_EXCEPTIONS_FOR_RESOURCE_LOADING(
    state: RolesAndPermissionsState
  ) {
    state.apiState.getRolesAndExceptionsForResource = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ROLES_AND_EXCEPTIONS_FOR_RESOURCE_SUCCESS(
    state: RolesAndPermissionsState,
    payload: RolesAndExceptionsResponse
  ) {
    state.apiState.getRolesAndExceptionsForResource = {
      ...defaultApiState,
      success: true
    };
    state.rolesAndExceptionsForResource = payload;
  },
  GET_ROLES_AND_EXCEPTIONS_FOR_RESOURCE_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getRolesAndExceptionsForResource = {
      ...defaultApiState,
      error
    };
  },
  // Add users to role
  ADD_USERS_TO_ROLE_LOADING(state: RolesAndPermissionsState) {
    state.apiState.addUsersToRole = {
      ...defaultApiState,
      loading: true
    };
  },
  ADD_USERS_TO_ROLE_SUCCESS(state: RolesAndPermissionsState) {
    state.apiState.addUsersToRole = {
      ...defaultApiState,
      success: true
    };
  },
  ADD_USERS_TO_ROLE_ERROR(state: RolesAndPermissionsState, error: AxiosError) {
    state.apiState.addUsersToRole = {
      ...defaultApiState,
      error
    };
  },
  // Add groups to role
  ADD_GROUPS_TO_ROLE_LOADING(state: RolesAndPermissionsState) {
    state.apiState.addGroupsToRole = {
      ...defaultApiState,
      loading: true
    };
  },
  ADD_GROUPS_TO_ROLE_SUCCESS(state: RolesAndPermissionsState) {
    state.apiState.addGroupsToRole = {
      ...defaultApiState,
      success: true
    };
  },
  ADD_GROUPS_TO_ROLE_ERROR(state: RolesAndPermissionsState, error: AxiosError) {
    state.apiState.addGroupsToRole = {
      ...defaultApiState,
      error
    };
  },
  /** Get exception for module */
  GET_EXCEPTIONS_BY_MODULE_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getExceptionsByModule = {
      ...defaultApiState,
      loading: true
    };
    state.moduleExceptions = undefined;
  },
  GET_EXCEPTIONS_BY_MODULE_SUCCESS(
    state: RolesAndPermissionsState,
    payload: ModuleExceptionsResponse
  ) {
    state.apiState.getExceptionsByModule = {
      ...defaultApiState,
      success: true
    };
    state.moduleExceptions = payload;
  },
  GET_EXCEPTIONS_BY_MODULE_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getExceptionsByModule = {
      ...defaultApiState,
      error
    };
  },

  /** Get resource exceptions for module */
  GET_RESOURCE_EXCEPTIONS_BY_MODULE_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getResourceExceptionsByModule = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_RESOURCE_EXCEPTIONS_BY_MODULE_SUCCESS(
    state: RolesAndPermissionsState,
    payload: Role
  ) {
    state.apiState.getResourceExceptionsByModule = {
      ...defaultApiState,
      success: true
    };
    state.resourceExceptions = payload;
  },
  GET_RESOURCE_EXCEPTIONS_BY_MODULE_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getResourceExceptionsByModule = {
      ...defaultApiState,
      error
    };
  },

  /** Get group exceptions */
  GET_GROUP_EXCEPTIONS_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getGroupExceptions = {
      ...defaultApiState,
      loading: true
    };
    state.groupExceptions = undefined;
  },
  GET_GROUP_EXCEPTIONS_SUCCESS(
    state: RolesAndPermissionsState,
    payload: ResourceExceptions
  ) {
    state.apiState.getGroupExceptions = {
      ...defaultApiState,
      success: true
    };
    state.groupExceptions = payload;
  },
  GET_GROUP_EXCEPTIONS_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getGroupExceptions = {
      ...defaultApiState,
      error
    };
  },

  /** Get resource instances exceptions */
  GET_RESOURCE_INSTANCES_EXCEPTIONS_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getResourceInstancesExceptions = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_RESOURCE_INSTANCES_EXCEPTIONS_SUCCESS(
    state: RolesAndPermissionsState,
    payload: ResourceExceptionDto[]
  ) {
    state.apiState.getResourceInstancesExceptions = {
      ...defaultApiState,
      success: true
    };
    state.resourceInstancesExceptions = payload;
  },
  GET_RESOURCE_INSTANCES_EXCEPTIONS_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getResourceInstancesExceptions = {
      ...defaultApiState,
      error
    };
  },

  // Create exception
  CREATE_EXCEPTION_LOADING(state: RolesAndPermissionsState) {
    state.apiState.createException = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_EXCEPTION_SUCCESS(state: RolesAndPermissionsState) {
    state.apiState.createException = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_EXCEPTION_ERROR(state: RolesAndPermissionsState, error: AxiosError) {
    state.apiState.createException = {
      ...defaultApiState,
      error
    };
  },
  // Update exception
  UPADTE_EXCEPTION_LOADING(state: RolesAndPermissionsState) {
    state.apiState.updateException = {
      ...defaultApiState,
      loading: true
    };
  },
  UPADTE_EXCEPTION_SUCCESS(state: RolesAndPermissionsState) {
    state.apiState.updateException = {
      ...defaultApiState,
      success: true
    };
  },
  UPADTE_EXCEPTION_ERROR(state: RolesAndPermissionsState, error: AxiosError) {
    state.apiState.updateException = {
      ...defaultApiState,
      error
    };
  },
  // Delete exception
  DELETE_EXCEPTION_LOADING(state: RolesAndPermissionsState) {
    state.apiState.deleteExceptions = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_EXCEPTION_SUCCESS(state: RolesAndPermissionsState) {
    state.apiState.deleteExceptions = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_EXCEPTION_ERROR(state: RolesAndPermissionsState, error: AxiosError) {
    state.apiState.deleteExceptions = {
      ...defaultApiState,
      error
    };
  },

  /**
   * Set/unset selected groups when selecting specific groups during group
   * creation/update
   */
  SET_SELECTED_GROUPS_FOR_VUE_TREESELECT(
    state: RolesAndPermissionsState,
    payload: SelectedGroupsForVueTreeselect[]
  ) {
    state.selectedGroupsForVueTreeselect = payload;
  },
  UNSET_SELECTED_GROUPS_FOR_VUE_TREESELECT(
    state: RolesAndPermissionsState,
    payload: SelectedGroupsForVueTreeselect[]
  ) {
    state.selectedGroupsForVueTreeselect = undefined;
  },

  // Get permissions for users
  GET_PERMISSIONS_FOR_USERS_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getPermissionsForUsers = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_PERMISSIONS_FOR_USERS_SUCCESS(
    state: RolesAndPermissionsState,
    payload: EntitiesWithPermissionsMatrix
  ) {
    state.apiState.getPermissionsForUsers = {
      ...defaultApiState,
      success: true
    };
    state.permissionsForUsers = {
      ...payload,
      entities: populateEntitiesWithDummyData(payload.entities),
      permissionsForModules: populatePermissionsWithDummyData(
        payload.permissionsForModules
      )
    } as EntitiesWithPermissionsMatrix;
  },
  GET_PERMISSIONS_FOR_USERS_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getPermissionsForUsers = {
      ...defaultApiState,
      error
    };
  },

  // Get permissions for groups
  GET_PERMISSIONS_FOR_GROUPS_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getPermissionsForGroups = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_PERMISSIONS_FOR_GROUPS_SUCCESS(
    state: RolesAndPermissionsState,
    payload: EntitiesWithPermissionsMatrix
  ) {
    state.apiState.getPermissionsForGroups = {
      ...defaultApiState,
      success: true
    };
    state.permissionsForGroups = {
      ...payload,
      entities: populateEntitiesWithDummyData(payload.entities),
      permissionsForModules: populatePermissionsWithDummyData(
        payload.permissionsForModules
      )
    } as EntitiesWithPermissionsMatrix;
  },
  GET_PERMISSIONS_FOR_GROUPS_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getPermissionsForGroups = {
      ...defaultApiState,
      error
    };
  },

  /**
   * Set current entity in view. This is used in permissions screen tab in
   * roles and permissions page. When admin clicks on a permission to check for
   * access conditions for that module for that user, the current entity
   * in view is stored in vuex along with the index of the user being
   * referenced.
   */
  SET_CURRENT_ENTITY_IN_VIEW(state: RolesAndPermissionsState, payload: string) {
    state.currentEntityInView = payload;
  },
  SET_CURRENT_ENTITY_IN_VIEW_ERROR(state: RolesAndPermissionsState) {
    state.currentEntityInView = undefined;
  },

  // Mutations for retrieving access conditions for a given entity
  GET_ACCESS_CONDITIONS_FOR_ENTITY_LOADING(state: RolesAndPermissionsState) {
    state.apiState.getAccessConditionsForEntity = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_ACCESS_CONDITIONS_FOR_ENTITY_SUCCESS(
    state: RolesAndPermissionsState,
    payload: AccessCondition
  ) {
    state.apiState.getAccessConditionsForEntity = {
      ...defaultApiState,
      success: true
    };
    state.accessConditionsForEntity = payload;
  },
  GET_ACCESS_CONDITIONS_FOR_ENTITY_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getAccessConditionsForEntity = {
      ...defaultApiState,
      error
    };
  },

  GET_FILTERED_PERMISSIONS_GROUP_BY_USER_ID_LOADING(
    state: RolesAndPermissionsState
  ) {
    state.apiState.getFilteredPermissionsGroupByUserId = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_FILTERED_PERMISSIONS_GROUP_BY_USER_ID_SUCCESS(
    state: RolesAndPermissionsState,
    groups: FilteredPermissionsGroupByUserIdResponse
  ) {
    state.filteredPermissionsGroupByUserId = groups;
    state.apiState.getFilteredPermissionsGroupByUserId = {
      ...defaultApiState,
      success: true
    };
  },
  GET_FILTERED_PERMISSIONS_GROUP_BY_USER_ID_ERROR(
    state: RolesAndPermissionsState,
    error: AxiosError
  ) {
    state.apiState.getFilteredPermissionsGroupByUserId = {
      ...defaultApiState,
      error
    };
  }
};
