import { RootState } from '@/store/store';
import { Module } from 'vuex';
import { actions } from './content.actions';
import { mutations } from './content.mutations';
import { ContentState, state } from './content.state';

const namespaced = true;

export const content: Module<ContentState, RootState> = {
  namespaced,
  state,
  mutations,
  actions
};
