import { roleApi } from '@/api/role.api';
import {
  CreateRolePayload,
  DeleteGroupsFromRoleQueryParams,
  DeleteInvitationsFromRoleQueryParams,
  DeleteUsersFromRoleQueryParams,
  GetRoleResourcesQueryParams,
  GetRolesQueryParams,
  RoleState,
  UpdateRolePayload
} from '@/store/modules/role/types/role.types';
import { AxiosError } from 'axios';
import { Store } from 'vuex';

export const roleActions: {
  getRoles(
    store: Store<RoleState>,
    payload: GetRolesQueryParams
  ): Promise<void>;
  getRoleById(store: Store<RoleState>, id: number): Promise<void>;
  createRole(
    store: Store<RoleState>,
    payload: CreateRolePayload
  ): Promise<void>;
  updateRole(
    store: Store<RoleState>,
    payload: UpdateRolePayload
  ): Promise<void>;
  deleteRole(store: Store<RoleState>, id: number): Promise<void>;
  getUsersAssignedToRole(
    store: Store<RoleState>,
    payload: GetRoleResourcesQueryParams
  ): Promise<void>;
  getGroupsAssignedToRole(
    store: Store<RoleState>,
    payload: GetRoleResourcesQueryParams
  ): Promise<void>;
  deleteUsersFromRole(
    store: Store<RoleState>,
    payload: DeleteUsersFromRoleQueryParams
  ): Promise<void>;
  deleteGroupsFromRole(
    store: Store<RoleState>,
    payload: DeleteGroupsFromRoleQueryParams
  ): Promise<void>;
  deleteInvitationsFromRole(
    store: Store<RoleState>,
    payload: DeleteInvitationsFromRoleQueryParams
  ): Promise<void>;
} = {
  async getRoles(
    store: Store<RoleState>,
    payload: GetRolesQueryParams
  ): Promise<void> {
    try {
      store.commit('GET_ROLES_LOADING');
      const { data } = await roleApi.getRoles({
        page: payload.page,
        limit: payload.limit,
        searchQuery: payload.searchQuery,
        sortOrder: payload.sortOrder,
        sortColumn: payload.sortColumn
      });
      store.commit('GET_ROLES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ROLES_ERROR', error);
    }
  },
  async getRoleById(store: Store<RoleState>, id: number) {
    try {
      store.commit('GET_ROLE_BY_ID_LOADING');
      const { data } = await roleApi.getRoleById(id);
      store.commit('GET_ROLE_BY_ID_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ROLE_BY_ID_ERROR', error);
    }
  },
  async createRole(store: Store<RoleState>, payload: CreateRolePayload) {
    try {
      store.commit('CREATE_ROLE_LOADING');
      const { data } = await roleApi.createRole(payload);
      store.commit('CREATE_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit(
        'CREATE_ROLE_ERROR',
        (error as AxiosError).response!.data.message
      );
    }
  },
  async updateRole(store: Store<RoleState>, payload: UpdateRolePayload) {
    try {
      store.commit('UPDATE_ROLE_LOADING');
      const { data } = await roleApi.updateRole(payload);
      store.commit('UPDATE_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit(
        'UPDATE_ROLE_ERROR',
        (error as AxiosError).response!.data.message
      );
    }
  },
  async deleteRole(store: Store<RoleState>, id: number) {
    try {
      store.commit('DELETE_ROLE_LOADING');
      const { data } = await roleApi.deleteRole(id);
      store.commit('DELETE_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_ROLE_ERROR', error);
    }
  },
  async getUsersAssignedToRole(
    store: Store<RoleState>,
    payload: GetRoleResourcesQueryParams
  ) {
    try {
      store.commit('GET_USERS_ASSIGNED_TO_ROLE_LOADING');
      const { data } = await roleApi.getUsersAssignedToRole(payload);
      store.commit('GET_USERS_ASSIGNED_TO_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USERS_ASSIGNED_TO_ROLE_ERROR', error);
    }
  },
  async getGroupsAssignedToRole(
    store: Store<RoleState>,
    payload: GetRoleResourcesQueryParams
  ) {
    try {
      store.commit('GET_GROUPS_ASSIGNED_TO_ROLE_LOADING');
      const { data } = await roleApi.getGroupsAssignedToRole(payload);
      store.commit('GET_GROUPS_ASSIGNED_TO_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUPS_ASSIGNED_TO_ROLE_ERROR', error);
    }
  },
  async deleteUsersFromRole(
    store: Store<RoleState>,
    payload: DeleteUsersFromRoleQueryParams
  ) {
    try {
      store.commit('DELETE_USERS_FROM_ROLE_LOADING');
      const { data } = await roleApi.deleteUsersFromRole(payload);
      store.commit('DELETE_USERS_FROM_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_USERS_FROM_ROLE_ERROR', error);
    }
  },
  async deleteGroupsFromRole(
    store: Store<RoleState>,
    payload: DeleteGroupsFromRoleQueryParams
  ) {
    try {
      store.commit('DELETE_GROUPS_FROM_ROLE_LOADING');
      const { data } = await roleApi.deleteGroupsFromRole(payload);
      store.commit('DELETE_GROUPS_FROM_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GROUPS_FROM_ROLE_ERROR', error);
    }
  },
  async deleteInvitationsFromRole(
    store: Store<RoleState>,
    payload: DeleteInvitationsFromRoleQueryParams
  ) {
    try {
      store.commit('DELETE_INVITATIONS_FROM_ROLE_LOADING');
      const { data } = await roleApi.deleteInvitationsFromRole(payload);
      store.commit('DELETE_INVITATIONS_FROM_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_INVITATIONS_FROM_ROLE_ERROR', error);
    }
  }
};
