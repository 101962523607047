export enum ModulePermissionTypes {
  ALLOW = 'allow',
  DENY = 'deny',
  MIXED = 'mixed',
  NONE = 'none',
}

export interface PermissionsForModule {
  moduleName: string;
  label: string;
  description: string;
  level: number;
  permissions: ModulePermissionTypes[];
  submodules: PermissionsForModule[];
}

/**
 * Users are sorted alphabetically by first name, by default, in the users
 * array.
 *
 * The `permissionsForModule` array contains a nested list of modules
 * with corresponding `permissions` arrays. The indexes of the items in this
 * array corresponding to the indexes of the items in the users array.
 *
 * So, the item at index 0 of the `group_administration` module is about the
 * permission of the user at index 0 in the users array.
 *
 * Example of this interface in use:
 * ```
 * {
 *   users: [
 *   {
 *       id: 2,
 *       email: 'alanwake@snappymob.com'
 *       firstName: 'Alan',
 *       lastName: 'Wake',
 *       isSuperAdmin: false
 *     },
 *     {
 *       id: 1,
 *       email: 'raj@snappymob.com'
 *       firstName: 'Raj',
 *       lastName: 'Saha',
 *       isSuperAdmin: false
 *     }
 *   ],
 *   permissionsForModules: [
 *     {
 *       moduleName: 'group_administration',
 *       label: 'Group Administration',
 *       permissions: [ModulePermissionTypes.ALLOW, ModulePermissionTypes.DENY],
 *       submodules: [
 *         {
 *           moduleName: 'groups',
 *           label: 'Groups',
 *           permissions: [ModulePermissionTypes.ALLOW, ModulePermissionTypes.DENY],
 *           submodules: []
 *         }
 *       ]
 *     }
 *   ]
 * }
 * ```
 * As you can see from the example above, Alan has access to the module
 * `group_administration` but Raj does not. Alan is at index 0 while Raj is at
 * index 1. Their permissions for module `group_administration` is similarly
 * at index 0 and 1 respectively.
 *
 * PLEASE NOTE
 * ---
 * This interface is different from the one found in JAAS backend. Keys from
 * UserEntity and UserProfileEntity have been added explicitly as jbi-shared
 * does not have access to said entities.
 */
export interface EntitiesVersusModules {
  users?: Array<{
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    isSuperAdmin: boolean;
  }>;
  groups?: { id: number; name: string }[];
  permissionsForModules: PermissionsForModule[];
}
