import { ThirdPartyUserExportToCsv } from '@/components/export/csv/third-party-user/ExportCsv';
import {
  GroupLevelAttribute,
  GroupLevelAttributeTemplate,
  GroupLevelAttributeValue
} from '@/jbi-shared/types/jaas-group-level-attributes.types';
import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import {
  GroupUserAttributeType,
  MyjbiGroupDetail,
  MyjbiGroupUserAttribute,
  MyjbiGroupUserAttributeSpec
} from '../../../jbi-shared/types/myjbi-group.types';
import {
  GetThirdPartyUsersResponsePayload,
  ThirdPartyUserCredentials
} from '../../../views/AdminThirdPartyManagement/types/admin-third-party-management.types';
import { Profile, ProfileState } from '../profile/profile.state';
import {
  AdminState,
  GetGroupsResponsePayload,
  GetGroupStatsResponsePayload,
  GetMembersResponsePayload,
  GetProtectedGroupTypesResponsePayload,
  GetSubGroupsResponsePayload,
  Group,
  GroupAttributeOrderResponsePayload,
  GroupDetail,
  GroupType,
  Invitation,
  InviteUserToProductPayload,
  Pagination,
  TermsAndConditions,
  User,
  UserByEmailResponsePayload
} from './types/admin.types';
import {
  AddressCountry,
  AddressState,
  CreateGroupLevelAttributeResponse,
  CreateGroupLevelAttributeTemplateResponse,
  GroupLevelAttributeType,
  GroupTemplate,
  UpdateGroupLevelAttributeResponse,
  UpdateGroupLevelAttributeTemplateResponse
} from './types/group-level-attribute.types';
import { GetGroupsLicenseResponsePayload } from './types/group-license.types';
import { GetGroupsProductResponsePayload } from './types/group-product.types';
import {
  CreateGroupUserAttributeTemplateUpStream,
  GroupUserAttributeTemplateUpStream
} from './types/group-user-attribute-template.types';
import {
  GetGroupUserAttributeListResponse,
  GetUploadImageSignedUrlResponse,
  GroupUserAttributeWithGroupCount,
  NewGroupUserAttributeResponse
} from './types/group-user-attribute.types';

export const adminMutations = {
  INVITE_NEW_USER_TO_PRODUCT_LOADING(state: AdminState) {
    state.apiState.inviteNewUserToProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  INVITE_NEW_USER_TO_PRODUCT_SUCCESS(state: AdminState, payload: any) {
    state.apiState.inviteNewUserToProduct = {
      ...defaultApiState,
      success: true
    };
  },
  INVITE_NEW_USER_TO_PRODUCT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.inviteNewUserToProduct = {
      ...defaultApiState,
      error
    };
  },
  GET_USER_INVITATIONS_LOADING(state: AdminState) {
    state.apiState.getUserInvitations = {
      ...defaultApiState,
      loading: true
    };
    state.userInvitations = undefined;
  },
  GET_USER_INVITATIONS_SUCCESS(state: AdminState, payload: any) {
    state.apiState.getUserInvitations = {
      ...defaultApiState,
      success: true
    };
    state.userInvitations = payload;
  },
  GET_USER_INVITATIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getUserInvitations = {
      ...defaultApiState,
      error
    };
  },

  BULK_INVITE_NEW_USER_TO_PRODUCT_LOADING(state: AdminState) {
    state.apiState.bulkInviteNewUserToProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  BULK_INVITE_NEW_USER_TO_PRODUCT_SUCCESS(state: AdminState) {
    state.apiState.bulkInviteNewUserToProduct = {
      ...defaultApiState,
      success: true
    };
  },
  BULK_INVITE_NEW_USER_TO_PRODUCT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.bulkInviteNewUserToProduct = {
      ...defaultApiState,
      error
    };
  },

  GENERATE_INVITATION_LINKS_LOADING(state: AdminState) {
    state.apiState.generateInvitationLinks = {
      ...defaultApiState,
      loading: true
    };
  },
  GENERATE_INVITATION_LINKS_SUCCEES(state: AdminState) {
    state.apiState.generateInvitationLinks = {
      ...defaultApiState,
      success: true
    };
  },
  GENERATE_INVITATION_LINKS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.generateInvitationLinks = {
      ...defaultApiState,
      error
    };
  },

  GET_INVITED_USERS_LOADING(state: AdminState) {
    state.apiState.getUsers = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_INVITED_USERS_SUCCESS(state: AdminState, payload: Pagination<User>) {
    state.apiState.getUsers = {
      ...defaultApiState,
      success: true
    };
    state.userDetails = payload;
  },
  GET_INVITED_USERS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getUsers = {
      ...defaultApiState,
      error
    };
  },
  REMOVE_USER_INVITATION_LOADING(state: AdminState) {
    state.apiState.removeUserInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_USER_INVITATION_SUCCESS(state: AdminState) {
    state.apiState.removeUserInvitation = {
      ...defaultApiState,
      success: true
    };
  },
  REMOVE_USER_INVITATION_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.removeUserInvitation = {
      ...defaultApiState,
      error
    };
  },
  REMOVE_INVITATION_LOADING(state: AdminState) {
    state.apiState.removeInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_INVITATION_SUCCESS(state: AdminState) {
    state.apiState.removeInvitation = {
      ...defaultApiState,
      success: true
    };
  },
  REMOVE_INVITATION_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.removeInvitation = {
      ...defaultApiState,
      error
    };
  },
  DISABLE_USER_LOADING(state: AdminState) {
    state.apiState.toggleUser = {
      ...defaultApiState,
      loading: true
    };
  },
  DISABLE_USER_SUCCESS(state: AdminState) {
    state.apiState.toggleUser = {
      ...defaultApiState,
      success: true
    };
  },
  DISABLE_USER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.toggleUser = {
      ...defaultApiState,
      error
    };
  },
  VERIFY_USER_LOADING(state: AdminState) {
    state.apiState.verifyUser = {
      ...defaultApiState,
      loading: true
    };
  },
  VERIFY_USER_SUCCESS(state: AdminState) {
    state.apiState.verifyUser = {
      ...defaultApiState,
      success: true
    };
  },
  VERIFY_USER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.verifyUser = {
      ...defaultApiState,
      error
    };
  },
  RESEND_USER_INVITATION_LOADING(state: AdminState) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      loading: true
    };
  },
  RESEND_USER_INVITATION_SUCCESS(state: AdminState) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      success: true
    };
  },
  RESEND_USER_INVITATION_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.resendUserInvitation = {
      ...defaultApiState,
      error
    };
  },
  GET_TERMS_AND_CONDITIONS_LOADING(state: AdminState) {
    state.apiState.getTermsAndConditions = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_TERMS_AND_CONDITIONS_SUCCESS(
    state: AdminState,
    payload: Pagination<TermsAndConditions>
  ) {
    state.apiState.getTermsAndConditions = {
      ...defaultApiState,
      success: true
    };
    state.termsAndConditionsPayload = payload;
  },
  GET_TERMS_AND_CONDITIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getTermsAndConditions = {
      ...defaultApiState,
      error
    };
  },
  GET_TERMS_AND_CONDITIONS_APPLICATION_LOADING(state: AdminState) {
    state.apiState.getTermsAndConditionsApplication = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_TERMS_AND_CONDITIONS_APPLICATION_SUCCESS(
    state: AdminState,
    payload: TermsAndConditions
  ) {
    state.apiState.getTermsAndConditionsApplication = {
      ...defaultApiState,
      success: true
    };
    state.termsAndConditionsApplicationPayload = payload;
  },
  GET_TERMS_AND_CONDITIONS_APPLICATION_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getTermsAndConditionsApplication = {
      ...defaultApiState,
      error
    };
  },
  GET_TERMS_AND_CONDITIONS_HISTORY_LOADING(state: AdminState) {
    state.apiState.getTermsAndConditionsHistory = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_TERMS_AND_CONDITIONS_HISTORY_SUCCESS(
    state: AdminState,
    payload: TermsAndConditions
  ) {
    state.apiState.getTermsAndConditionsHistory = {
      ...defaultApiState,
      success: true
    };
    state.termsAndConditionsHistoryPayload = payload;
  },
  GET_TERMS_AND_CONDITIONS_HISTORY_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getTermsAndConditionsHistory = {
      ...defaultApiState,
      error
    };
  },
  ADD_TERMS_AND_CONDITIONS_LOADING(state: AdminState) {
    state.apiState.addTermsAndConditions = {
      ...defaultApiState,
      loading: true
    };
  },
  ADD_TERMS_AND_CONDITIONS_SUCCESS(state: AdminState) {
    state.apiState.addTermsAndConditions = {
      ...defaultApiState,
      success: true
    };
  },
  ADD_TERMS_AND_CONDITIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.addTermsAndConditions = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_TERMS_AND_CONDITIONS_LOADING(state: AdminState) {
    state.apiState.updateTermsAndConditions = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_TERMS_AND_CONDITIONS_SUCCESS(state: AdminState) {
    state.apiState.updateTermsAndConditions = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_TERMS_AND_CONDITIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateTermsAndConditions = {
      ...defaultApiState,
      error
    };
  },
  DELETE_TERMS_AND_CONDITIONS_LOADING(state: AdminState) {
    state.apiState.deleteTermsAndConditions = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_TERMS_AND_CONDITIONS_SUCCESS(state: AdminState) {
    state.apiState.deleteTermsAndConditions = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_TERMS_AND_CONDITIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.deleteTermsAndConditions = {
      ...defaultApiState,
      error
    };
  },
  SET_DEFAULT_TERMS_AND_CONDITIONS_LOADING(state: AdminState) {
    state.apiState.setDefaultTermsAndConditions = {
      ...defaultApiState,
      loading: true
    };
  },
  SET_DEFAULT_TERMS_AND_CONDITIONS_SUCCESS(state: AdminState) {
    state.apiState.setDefaultTermsAndConditions = {
      ...defaultApiState,
      success: true
    };
  },
  SET_DEFAULT_TERMS_AND_CONDITIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.setDefaultTermsAndConditions = {
      ...defaultApiState,
      error
    };
  },
  GET_INVITATIONS_LOADING(state: AdminState) {
    state.apiState.getInvitations = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_INVITATIONS_SUCCESS(state: AdminState, payload: Invitation[]) {
    state.apiState.getInvitations = {
      ...defaultApiState,
      success: true
    };
    state.invitations = payload;
  },
  GET_INVITATIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getInvitations = {
      ...defaultApiState,
      error
    };
  },
  GET_SUBSCRIPTIONS_LOADING(state: AdminState) {
    state.apiState.getSubscriptions = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_SUBSCRIPTIONS_SUCCESS(state: AdminState, payload: any) {
    state.apiState.getSubscriptions = {
      ...defaultApiState,
      success: true
    };
    state.subscriptions = payload;
  },
  GET_SUBSCRIPTIONS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getSubscriptions = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_SUBSCRIPTION_STATUS_LOADING(state: AdminState) {
    state.apiState.updateSubscriptionStatus = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_SUBSCRIPTION_STATUS_SUCCESS(state: AdminState) {
    state.apiState.updateSubscriptionStatus = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_SUBSCRIPTION_STATUS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateSubscriptionStatus = {
      ...defaultApiState,
      error
    };
  },

  CREATE_NEW_GROUP_LOADING(state: AdminState) {
    state.apiState.createNewGroup = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_NEW_GROUP_SUCCESS(state: AdminState) {
    state.apiState.createNewGroup = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_NEW_GROUP_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createNewGroup = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUPS_LOADING(state: AdminState) {
    state.apiState.getGroups = {
      ...defaultApiState,
      loading: true
    };
    state.groups = undefined;
  },
  GET_GROUPS_SUCCESS(state: AdminState, data: GetGroupsResponsePayload) {
    state.apiState.getGroups = {
      ...defaultApiState,
      success: true
    };
    state.groups = data;
  },
  GET_GROUPS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroups = {
      ...defaultApiState,
      error
    };
  },

  GET_SPECIFIC_GROUPS_LOADING(state: AdminState) {
    state.apiState.getSpecificGroups = {
      ...defaultApiState,
      loading: true
    };
    state.specificGroups = undefined;
  },
  GET_SPECIFIC_GROUPS_SUCCESS(state: AdminState, data: Group[]) {
    state.apiState.getSpecificGroups = {
      ...defaultApiState,
      success: true
    };
    state.specificGroups = data;
  },
  GET_SPECIFIC_GROUPS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getSpecificGroups = {
      ...defaultApiState,
      error
    };
  },

  GET_SIMPLIFIED_GROUPS_LOADING(state: AdminState) {
    state.apiState.getSimplifiedGroups = {
      ...defaultApiState,
      loading: true
    };
    state.simplifiedGroups = undefined;
  },
  GET_SIMPLIFIED_GROUPS_SUCCESS(state: AdminState, data: Group[]) {
    state.apiState.getSimplifiedGroups = {
      ...defaultApiState,
      success: true
    };
    state.simplifiedGroups = data;
  },
  GET_SIMPLIFIED_GROUPS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getSimplifiedGroups = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUPS_FROM_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.getGroupsFromAttribute = {
      ...defaultApiState,
      loading: true
    };
    state.groupsFromAttribute = undefined;
  },
  GET_GROUPS_FROM_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: GetGroupsResponsePayload
  ) {
    state.apiState.getGroupsFromAttribute = {
      ...defaultApiState,
      success: true
    };
    state.groupsFromAttribute = data;
  },
  GET_GROUPS_FROM_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupsFromAttribute = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_DETAIL_LOADING(state: AdminState) {
    state.apiState.getGroupDetail = {
      ...defaultApiState,
      loading: true
    };
    state.groupDetail = undefined;
  },
  GET_GROUP_DETAIL_SUCCESS(state: AdminState, data: GroupDetail) {
    state.apiState.getGroupDetail = {
      ...defaultApiState,
      success: true
    };
    state.groupDetail = data;
  },
  GET_GROUP_DETAIL_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupDetail = {
      ...defaultApiState,
      error
    };
  },

  GET_SUB_GROUPS_LOADING(state: AdminState) {
    state.apiState.getSubGroups = {
      ...defaultApiState,
      loading: true
    };
    state.subGroups = undefined;
  },
  GET_SUB_GROUPS_SUCCESS(state: AdminState, data: GetSubGroupsResponsePayload) {
    state.apiState.getSubGroups = {
      ...defaultApiState,
      success: true
    };
    state.subGroups = data;
  },
  GET_SUB_GROUPS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getSubGroups = {
      ...defaultApiState,
      error
    };
  },

  GET_MEMBERS_LOADING(state: AdminState) {
    state.apiState.getMembers = {
      ...defaultApiState,
      loading: true
    };
    state.members = undefined;
  },
  GET_MEMBERS_SUCCESS(state: AdminState, data: GetMembersResponsePayload) {
    state.apiState.getMembers = {
      ...defaultApiState,
      success: true
    };
    state.members = data;
  },
  GET_MEMBERS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getMembers = {
      ...defaultApiState,
      error
    };
  },

  GET_MASTER_GROUP_EMAIL_DOMAINS_LOADING(state: AdminState) {
    state.apiState.getMasterGroupEmailDomains = {
      ...defaultApiState,
      loading: true
    };
    state.groupDetail = undefined;
  },
  GET_MASTER_GROUP_EMAIL_DOMAINS_SUCCESS(state: AdminState, data: string[]) {
    state.apiState.getMasterGroupEmailDomains = {
      ...defaultApiState,
      success: true
    };
    state.masterGroupEmailDomains = data;
  },
  GET_MASTER_GROUP_EMAIL_DOMAINS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getMasterGroupEmailDomains = {
      ...defaultApiState,
      error
    };
  },

  GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID_LOADING(state: AdminState) {
    state.apiState.getParentGroupDetailByParentGroupId = {
      ...defaultApiState,
      loading: true
    };
    state.parentGroupDetailByParentGroupId = undefined;
  },
  GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID_SUCCESS(
    state: AdminState,
    data: MyjbiGroupDetail
  ) {
    state.apiState.getParentGroupDetailByParentGroupId = {
      ...defaultApiState,
      success: true
    };
    state.parentGroupDetailByParentGroupId = data;
  },
  GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getParentGroupDetailByParentGroupId = {
      ...defaultApiState,
      error
    };
  },
  RESET_GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID(state: AdminState) {
    state.apiState.getParentGroupDetailByParentGroupId = {
      ...defaultApiState
    };
    state.parentGroupDetailByParentGroupId = undefined;
  },

  GET_PARENT_GROUP_DETAIL_BY_CHILD_GROUP_ID_LOADING(state: AdminState) {
    state.apiState.getParentGroupDetailByChildGroupId = {
      ...defaultApiState,
      loading: true
    };
    state.parentGroupDetailByChildGroupId = undefined;
  },
  GET_PARENT_GROUP_DETAIL_BY_CHILD_GROUP_ID_SUCCESS(
    state: AdminState,
    data: MyjbiGroupDetail
  ) {
    state.apiState.getParentGroupDetailByChildGroupId = {
      ...defaultApiState,
      success: true
    };
    state.parentGroupDetailByChildGroupId = data;
  },
  GET_PARENT_GROUP_DETAIL_BY_CHILD_GROUP_ID_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getParentGroupDetailByChildGroupId = {
      ...defaultApiState,
      error
    };
  },

  GET_PROTECTED_GROUP_TYPES_LOADING(state: AdminState) {
    state.apiState.getGroupAttributeTypes = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_PROTECTED_GROUP_TYPES_SUCCESS(
    state: AdminState,
    data: GetProtectedGroupTypesResponsePayload
  ) {
    state.apiState.getGroupAttributeTypes = {
      ...defaultApiState,
      success: true
    };
    state.protectedGroupTypes = data;
  },
  GET_PROTECTED_GROUP_TYPES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupAttributeTypes = {
      ...defaultApiState,
      error
    };
  },

  ADD_MEMBERS_TO_GROUP_LOADING(state: AdminState) {
    state.apiState.addMembersToGroup = {
      ...defaultApiState,
      loading: true
    };
  },
  ADD_MEMBERS_TO_GROUP_SUCCESS(state: AdminState) {
    state.apiState.addMembersToGroup = {
      ...defaultApiState,
      success: true
    };
  },
  ADD_MEMBERS_TO_GROUP_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.addMembersToGroup = {
      ...defaultApiState,
      error
    };
  },

  EDIT_GROUP_MEMBER_LOADING(state: AdminState) {
    state.apiState.editGroupMember = {
      ...defaultApiState,
      loading: true
    };
  },
  EDIT_GROUP_MEMBER_SUCCESS(state: AdminState) {
    state.apiState.editGroupMember = {
      ...defaultApiState,
      success: true
    };
  },
  EDIT_GROUP_MEMBER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.editGroupMember = {
      ...defaultApiState,
      error
    };
  },

  DELETE_GROUP_MEMBER_LOADING(state: AdminState) {
    state.apiState.deleteGroupMember = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_MEMBER_SUCCESS(state: AdminState) {
    state.apiState.deleteGroupMember = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_MEMBER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.deleteGroupMember = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_LOADING(state: AdminState) {
    state.apiState.updateGroup = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_SUCCESS(state: AdminState) {
    state.apiState.updateGroup = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_GROUP_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateGroup = {
      ...defaultApiState,
      error
    };
  },
  REMOVE_EMAIL_DOMAIN_LOADING(state: AdminState) {
    state.apiState.removeEmailDomain = {
      ...defaultApiState,
      loading: true
    };
  },
  REMOVE_EMAIL_DOMAIN_SUCCESS(state: AdminState) {
    state.apiState.removeEmailDomain = {
      ...defaultApiState,
      success: true
    };
  },
  REMOVE_EMAIL_DOMAIN_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.removeEmailDomain = {
      ...defaultApiState,
      error
    };
  },

  DELETE_GROUP_LOADING(state: AdminState) {
    state.apiState.deleteGroup = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_SUCCESS(state: AdminState) {
    state.apiState.deleteGroup = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.deleteGroup = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_PRODUCT_LOADING(state: AdminState) {
    state.apiState.createGroupProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_GROUP_PRODUCT_SUCCESS(state: AdminState) {
    state.apiState.createGroupProduct = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_GROUP_PRODUCT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createGroupProduct = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_PRODUCTS_LOADING(state: AdminState) {
    state.apiState.getGroupProducts = {
      ...defaultApiState,
      loading: true
    };
    state.groupProducts = undefined;
  },
  GET_GROUP_PRODUCTS_SUCCESS(
    state: AdminState,
    data: GetGroupsProductResponsePayload
  ) {
    state.apiState.getGroupProducts = {
      ...defaultApiState,
      success: true
    };
    state.groupProducts = data;
  },
  GET_GROUP_PRODUCTS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupProducts = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_PRODUCT_LOADING(state: AdminState) {
    state.apiState.updateGroupProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_PRODUCT_SUCCESS(state: AdminState) {
    state.apiState.updateGroupProduct = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_GROUP_PRODUCT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateGroupProduct = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_LICENSE_LOADING(state: AdminState) {
    state.apiState.createGroupLicense = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_GROUP_LICENSE_SUCCESS(state: AdminState) {
    state.apiState.createGroupLicense = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_GROUP_LICENSE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createGroupLicense = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_STATS_LOADING(state: AdminState) {
    state.apiState.getGroupStats = {
      ...defaultApiState,
      loading: true
    };
    state.groupStats = undefined;
  },
  GET_GROUP_STATS_SUCCESS(
    state: AdminState,
    data: GetGroupStatsResponsePayload
  ) {
    state.apiState.getGroupStats = {
      ...defaultApiState,
      success: true
    };
    state.groupStats = data;
  },
  GET_GROUP_STATS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupStats = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_LICENSES_LOADING(state: AdminState) {
    state.apiState.getGroupLicenses = {
      ...defaultApiState,
      loading: true
    };
    state.groupLicenses = undefined;
  },
  GET_GROUP_LICENSES_SUCCESS(
    state: AdminState,
    data: GetGroupsLicenseResponsePayload
  ) {
    state.apiState.getGroupLicenses = {
      ...defaultApiState,
      success: true
    };
    state.groupLicenses = data;
  },
  GET_GROUP_LICENSES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupLicenses = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_LICENSE_LOADING(state: AdminState) {
    state.apiState.updateGroupLicense = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_LICENSE_SUCCESS(state: AdminState) {
    state.apiState.updateGroupLicense = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_GROUP_LICENSE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateGroupLicense = {
      ...defaultApiState,
      error
    };
  },

  DELETE_GROUP_LICENSE_LOADING(state: AdminState) {
    state.apiState.deleteGroupLicense = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_LICENSE_SUCCESS(state: AdminState) {
    state.apiState.deleteGroupLicense = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_LICENSE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.deleteGroupLicense = {
      ...defaultApiState,
      error
    };
  },

  RESEND_EMAIL_NOTIFICATION_TO_GROUP_MEMBER_LOADING(state: AdminState) {
    state.apiState.resendEmailNotificationToGroupMember = {
      ...defaultApiState,
      loading: true
    };
  },
  RESEND_EMAIL_NOTIFICATION_TO_GROUP_MEMBER_SUCCESS(state: AdminState) {
    state.apiState.resendEmailNotificationToGroupMember = {
      ...defaultApiState,
      success: true
    };
  },
  RESEND_EMAIL_NOTIFICATION_TO_GROUP_MEMBER_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.resendEmailNotificationToGroupMember = {
      ...defaultApiState,
      error
    };
  },
  EXPORT_CSV_MEMBERS_LIST_LOADING(state: AdminState) {
    state.apiState.exportMembersListToCsv = {
      ...defaultApiState,
      loading: true
    };
  },
  EXPORT_CSV_MEMBERS_LIST_SUCCESS(state: AdminState) {
    state.apiState.exportMembersListToCsv = {
      ...defaultApiState,
      success: true
    };
  },
  EXPORT_CSV_MEMBERS_LIST_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.exportMembersListToCsv = {
      ...defaultApiState,
      error
    };
  },
  EXPORT_CSV_DELETE_MEMBERS_LIST_LOADING(state: AdminState) {
    state.apiState.exportDeleteMembersListToCsv = {
      ...defaultApiState,
      loading: true
    };
  },
  EXPORT_CSV_DELETE_MEMBERS_LIST_SUCCESS(state: AdminState) {
    state.apiState.exportDeleteMembersListToCsv = {
      ...defaultApiState,
      success: true
    };
  },
  EXPORT_CSV_DELETE_MEMBERS_LIST_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.exportDeleteMembersListToCsv = {
      ...defaultApiState,
      error
    };
  },

  UPLOAD_USER_LIST_FILE_LOADING(state: AdminState) {
    state.apiState.uploadUserListFile = {
      ...defaultApiState,
      loading: true
    };
    state.groupLicenses = undefined;
  },
  UPLOAD_USER_LIST_FILE_SUCCESS(state: AdminState) {
    state.apiState.uploadUserListFile = {
      ...defaultApiState,
      success: true
    };
  },
  UPLOAD_USER_LIST_FILE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.uploadUserListFile = {
      ...defaultApiState,
      error
    };
  },
  VALIDATE_SINGLE_USER_PRODUCT_LOADING(state: AdminState) {
    state.apiState.validateSingleUserProduct = {
      ...defaultApiState,
      loading: true
    };
  },
  VALIDATE_SINGLE_USER_PRODUCT_SUCCESS(
    state: AdminState,
    data: InviteUserToProductPayload
  ) {
    state.apiState.validateSingleUserProduct = {
      ...defaultApiState,
      success: true
    };
    state.validateSingleUserProduct = data;
  },
  VALIDATE_SINGLE_USER_PRODUCT_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.validateSingleUserProduct = {
      ...defaultApiState,
      error
    };
  },
  GET_GROUP_PRODUCTS_NAME_LIST(state: AdminState, payload: any) {
    state.groupProductsNameList = payload;
  },
  GET_GROUP_LIST(state: AdminState, payload: any) {
    state.groupList = payload;
  },

  GET_GROUP_ATTRIBUTE_ORDER_LOADING(state: AdminState) {
    state.apiState.getGroupAttributeOrder = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_GROUP_ATTRIBUTE_ORDER_SUCCESS(
    state: AdminState,
    data: GroupAttributeOrderResponsePayload
  ) {
    state.apiState.getGroupAttributeOrder = {
      ...defaultApiState,
      success: true
    };
    state.groupAttributeOrder = data;
  },

  GET_GROUP_ATTRIBUTE_ORDER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupAttributeOrder = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_ATTRIBUTE_ORDER_LOADING(state: AdminState) {
    state.apiState.getGroupAttributeOrder = {
      ...defaultApiState,
      loading: true
    };
  },

  UPDATE_GROUP_ATTRIBUTE_ORDER_SUCCESS(
    state: AdminState,
    data: GroupAttributeOrderResponsePayload
  ) {
    state.apiState.getGroupAttributeOrder = {
      ...defaultApiState,
      success: true
    };
    state.groupAttributeOrder = data;
  },

  UPDATE_GROUP_ATTRIBUTE_ORDER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupAttributeOrder = {
      ...defaultApiState,
      error
    };
  },

  GET_THIRD_PARTY_USERS_LOADING(state: AdminState) {
    state.apiState.getThirdPartyUsers = {
      ...defaultApiState,
      loading: true
    };
    state.thirdPartyUsers = undefined;
  },
  GET_THIRD_PARTY_USERS_SUCCESS(
    state: AdminState,
    data: GetThirdPartyUsersResponsePayload
  ) {
    state.apiState.getThirdPartyUsers = {
      ...defaultApiState,
      success: true
    };
    state.thirdPartyUsers = data;
  },
  GET_THIRD_PARTY_USERS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getThirdPartyUsers = {
      ...defaultApiState,
      error
    };
  },

  CREATE_THIRD_PARTY_USERS_LOADING(state: AdminState) {
    state.apiState.createThirdPartyUser = {
      ...defaultApiState,
      loading: true
    };
    state.thirdPartyUsers = undefined;
  },
  CREATE_THIRD_PARTY_USERS_SUCCESS(
    state: AdminState,
    data: ThirdPartyUserCredentials
  ) {
    state.apiState.createThirdPartyUser = {
      ...defaultApiState,
      success: true
    };
    const exportToCsv = new ThirdPartyUserExportToCsv();
    exportToCsv.create(data);
  },
  CREATE_THIRD_PARTY_USERS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createThirdPartyUser = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_THIRD_PARTY_USER_LOADING(state: AdminState) {
    state.apiState.updateThirdPartyUser = {
      ...defaultApiState,
      loading: true
    };
    state.thirdPartyUsers = undefined;
  },
  UPDATE_THIRD_PARTY_USER_SUCCESS(state: AdminState) {
    state.apiState.updateThirdPartyUser = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_THIRD_PARTY_USER_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateThirdPartyUser = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_TYPES_LOADING(state: AdminState) {
    state.apiState.getGroupTypes = {
      ...defaultApiState,
      loading: true
    };
    state.groupTypes = undefined;
  },
  GET_GROUP_TYPES_SUCCESS(state: AdminState, data: Pagination<GroupType>) {
    state.apiState.getGroupTypes = {
      ...defaultApiState,
      success: true
    };
    state.groupTypes = data;
  },
  GET_GROUP_TYPES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupTypes = {
      ...defaultApiState,
      error
    };
  },
  GROUP_TYPE_LOADING(state: AdminState) {
    state.apiState.groupType = {
      ...defaultApiState,
      loading: true
    };
  },

  GROUP_TYPE_SUCCESS(state: AdminState, data: Pagination<GroupType>) {
    state.apiState.groupType = {
      ...defaultApiState,
      success: true
    };
  },
  GROUP_TYPE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.groupType = {
      ...defaultApiState,
      error
    };
  },

  GET_USER_BY_EMAIL_LOADING(state: AdminState) {
    state.apiState.getUserByEmail = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_USER_BY_EMAIL_SUCCESS(
    state: AdminState,
    payload: UserByEmailResponsePayload
  ) {
    state.apiState.getUserByEmail = {
      ...defaultApiState,
      success: true
    };
    state.userByEmailResponse = payload;
  },
  GET_USER_BY_EMAIL_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getUserByEmail = {
      ...defaultApiState,
      error
    };
  },

  GET_MEMBERS_COUNT_BY_GROUPID_LOADING(state: AdminState) {
    state.apiState.getMembersCountByGroupId = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_MEMBERS_COUNT_BY_GROUPID_SUCCESS(state: AdminState, payload: number) {
    state.apiState.getMembersCountByGroupId = {
      ...defaultApiState,
      success: true
    };
  },
  GET_MEMBERS_COUNT_BY_GROUPID_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getMembersCountByGroupId = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** get user detail by userid for admin to edit
   */
  GET_USER_LOADING(state: AdminState) {
    state.apiState.getUserByAdmin = {
      ...defaultApiState,
      loading: true
    };
    state.userProfile = null;
  },
  GET_USER_SUCCESS(state: AdminState, payload: Profile) {
    state.apiState.getUserByAdmin = {
      ...defaultApiState,
      success: true
    };
    state.userProfile = payload;
  },
  GET_USER_ERROR(state: AdminState, error: Error) {
    state.apiState.getUserByAdmin = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** update user profile by admin
   */
  UPDATE_USER_LOADING(state: AdminState) {
    state.apiState.updateUserByAdmin = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_USER_SUCCESS(state: AdminState, payload: Profile) {
    state.apiState.updateUserByAdmin = {
      ...defaultApiState,
      success: true
    };

    state.userProfile = {
      ...state.userProfile,
      ...payload,
      firstName: payload?.userProfile
        ? payload.userProfile.firstName
        : (state.userProfile?.firstName as string),
      lastName: payload?.userProfile
        ? payload.userProfile.lastName
        : (state.userProfile?.lastName as string)
    };
  },
  UPDATE_USER_ERROR(state: AdminState, error: Error) {
    state.apiState.updateUserByAdmin = {
      ...defaultApiState,
      error
    };
  },

  /*
   ** update user profile image by admin
   */
  UPDATE_PROFILE_IMAGE_LOADING(state: ProfileState) {
    state.apiState.updateUserProfileImage = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_PROFILE_IMAGE_SUCCESS(state: ProfileState) {
    state.apiState.updateUserProfileImage = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_PROFILE_IMAGE_ERROR(state: ProfileState, error: Error) {
    state.apiState.updateUserProfileImage = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_LEVEL_ATTRIBUTE_TYPES_LOADING(state: AdminState) {
    state.apiState.getGroupLevelAttributeTypes = {
      ...defaultApiState,
      loading: true
    };
    state.groupLevelAttributeTypes = undefined;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_TYPES_SUCCESS(
    state: AdminState,
    data: GroupLevelAttributeType[]
  ) {
    state.apiState.getGroupLevelAttributeTypes = {
      ...defaultApiState,
      success: true
    };
    state.groupLevelAttributeTypes = data;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_TYPES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupLevelAttributeTypes = {
      ...defaultApiState,
      error
    };
  },

  GET_PAGINATED_GROUP_LEVEL_ATTRIBUTES_LOADING(state: AdminState) {
    state.apiState.getPaginatedGroupLevelAttributes = {
      ...defaultApiState,
      loading: true
    };
    state.paginatedGroupLevelAttributes = undefined;
  },
  GET_PAGINATED_GROUP_LEVEL_ATTRIBUTES_SUCCESS(
    state: AdminState,
    data: Pagination<GroupLevelAttribute>
  ) {
    state.apiState.getPaginatedGroupLevelAttributes = {
      ...defaultApiState,
      success: true
    };
    state.paginatedGroupLevelAttributes = data;
  },
  GET_PAGINATED_GROUP_LEVEL_ATTRIBUTES_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getPaginatedGroupLevelAttributes = {
      ...defaultApiState,
      error
    };
  },

  GET_ALL_GROUP_LEVEL_ATTRIBUTES_LOADING(state: AdminState) {
    state.apiState.getAllGroupLevelAttributes = {
      ...defaultApiState,
      loading: true
    };
    state.allGroupLevelAttributes = undefined;
  },
  GET_ALL_GROUP_LEVEL_ATTRIBUTES_SUCCESS(
    state: AdminState,
    data: GroupLevelAttribute[]
  ) {
    state.apiState.getAllGroupLevelAttributes = {
      ...defaultApiState,
      success: true
    };
    state.allGroupLevelAttributes = data;
  },
  GET_ALL_GROUP_LEVEL_ATTRIBUTES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getAllGroupLevelAttributes = {
      ...defaultApiState,
      error
    };
  },

  GET_DEFAULT_GROUP_LEVEL_ATTRIBUTES_LOADING(state: AdminState) {
    state.apiState.getDefaultGroupLevelAttributes = {
      ...defaultApiState,
      loading: true
    };
    state.defaultGroupLevelAttributes = undefined;
  },
  GET_DEFAULT_GROUP_LEVEL_ATTRIBUTES_SUCCESS(
    state: AdminState,
    data: GroupLevelAttribute[]
  ) {
    state.apiState.getDefaultGroupLevelAttributes = {
      ...defaultApiState,
      success: true
    };
    state.defaultGroupLevelAttributes = data;
  },
  GET_DEFAULT_GROUP_LEVEL_ATTRIBUTES_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getDefaultGroupLevelAttributes = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_LEVEL_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.getGroupLevelAttributeById = {
      ...defaultApiState,
      loading: true
    };
    state.groupLevelAttribute = undefined;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: GroupLevelAttribute
  ) {
    state.apiState.getGroupLevelAttributeById = {
      ...defaultApiState,
      success: true
    };
    state.groupLevelAttribute = data;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupLevelAttributeById = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_LEVEL_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.updateGroupLevelAttribute = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_LEVEL_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: UpdateGroupLevelAttributeResponse
  ) {
    state.apiState.updateGroupLevelAttribute = {
      ...defaultApiState,
      success: true
    };
    state.updatedGroupLevelAttribute = data;
  },
  UPDATE_GROUP_LEVEL_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateGroupLevelAttribute = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_LEVEL_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.createGroupLevelAttribute = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_GROUP_LEVEL_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: CreateGroupLevelAttributeResponse
  ) {
    state.apiState.createGroupLevelAttribute = {
      ...defaultApiState,
      success: true
    };
    state.newGroupLevelAttribute = data;
  },
  CREATE_GROUP_LEVEL_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createGroupLevelAttribute = {
      ...defaultApiState,
      error
    };
  },

  VERIFY_GROUP_LEVEL_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.verifyGroupLevelAttribute = {
      ...defaultApiState,
      loading: true
    };
    state.verifyGroupLevelAttribute = undefined;
  },
  VERIFY_GROUP_LEVEL_ATTRIBUTE_SUCCESS(state: AdminState, data: boolean) {
    state.apiState.verifyGroupLevelAttribute = {
      ...defaultApiState,
      success: true
    };
    state.verifyGroupLevelAttribute = data;
  },
  VERIFY_GROUP_LEVEL_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.verifyGroupLevelAttribute = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_LEVEL_ATTRIBUTE_VALUES_LOADING(state: AdminState) {
    state.apiState.getGroupLevelAttributeValuesByGroupId = {
      ...defaultApiState,
      loading: true
    };

    state.groupLevelAttributeValuesByGroupId = undefined;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_VALUES_SUCCESS(
    state: AdminState,
    data: GroupLevelAttributeValue[]
  ) {
    state.apiState.getGroupLevelAttributeValuesByGroupId = {
      ...defaultApiState,
      success: true
    };
    state.groupLevelAttributeValuesByGroupId = data;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_VALUES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupLevelAttributeValuesByGroupId = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_LEVEL_ATTRIBUTE_VALUES_LOADING(state: AdminState) {
    state.apiState.createGroupLevelAttributeValues = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_GROUP_LEVEL_ATTRIBUTE_VALUES_SUCCESS(state: AdminState) {
    state.apiState.createGroupLevelAttributeValues = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_GROUP_LEVEL_ATTRIBUTE_VALUES_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.createGroupLevelAttributeValues = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_LEVEL_ATTRIBUTE_VALUES_LOADING(state: AdminState) {
    state.apiState.updateGroupLevelAttributeValues = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_LEVEL_ATTRIBUTE_VALUES_SUCCESS(state: AdminState) {
    state.apiState.updateGroupLevelAttributeValues = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_GROUP_LEVEL_ATTRIBUTE_VALUES_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.updateGroupLevelAttributeValues = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_USER_ATTRIBUTE_TYPES_LOADING(state: AdminState) {
    state.apiState.getGroupUserAttributeTypes = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributeTypes = undefined;
  },
  GET_GROUP_USER_ATTRIBUTE_TYPES_SUCCESS(
    state: AdminState,
    data: GroupUserAttributeType[]
  ) {
    state.apiState.getGroupUserAttributeTypes = {
      ...defaultApiState,
      success: true
    };
    state.groupUserAttributeTypes = data;
  },
  GET_GROUP_USER_ATTRIBUTE_TYPES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupUserAttributeTypes = {
      ...defaultApiState,
      error
    };
  },

  VERIFY_GROUP_USER_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.verifyGroupUserAttribute = {
      ...defaultApiState,
      loading: true
    };
    state.verifyGroupUserAttribute = undefined;
  },
  VERIFY_GROUP_USER_ATTRIBUTE_SUCCESS(state: AdminState, data: boolean) {
    state.apiState.verifyGroupUserAttribute = {
      ...defaultApiState,
      success: true
    };
    state.verifyGroupUserAttribute = data;
  },
  VERIFY_GROUP_USER_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.verifyGroupUserAttribute = {
      ...defaultApiState,
      error
    };
  },

  /**
   * Retrieves single record with group count
   */
  GET_GROUP_USER_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.getGroupUserAttributeById = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributeById = undefined;
  },
  GET_GROUP_USER_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: GroupUserAttributeWithGroupCount
  ) {
    state.apiState.getGroupUserAttributeById = {
      ...defaultApiState,
      success: true
    };
    state.groupUserAttributeById = data;
  },
  GET_GROUP_USER_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupUserAttributeById = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_USER_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.createGroupUserAttribute = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_GROUP_USER_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: NewGroupUserAttributeResponse
  ) {
    state.apiState.createGroupUserAttribute = {
      ...defaultApiState,
      success: true
    };
    state.newGroupUserAttribute = data;
  },
  CREATE_GROUP_USER_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createGroupUserAttribute = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_USER_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.updateGroupUserAttribute = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_USER_ATTRIBUTE_SUCCESS(
    state: AdminState,
    data: NewGroupUserAttributeResponse
  ) {
    state.apiState.updateGroupUserAttribute = {
      ...defaultApiState,
      success: true
    };
    state.updatedGroupUserAttribute = data;
  },
  UPDATE_GROUP_USER_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateGroupUserAttribute = {
      ...defaultApiState,
      error
    };
  },

  DELETE_GROUP_USER_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.deleteGroupUserAttribute = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_USER_ATTRIBUTE_SUCCESS(state: AdminState) {
    state.apiState.deleteGroupUserAttribute = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_USER_ATTRIBUTE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.deleteGroupUserAttribute = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUPS_WITH_ATTRIBUTE_LOADING(state: AdminState): void {
    state.apiState.updateGroupsWithAttribute = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUPS_WITH_ATTRIBUTE_SUCCESS(state: AdminState): void {
    state.apiState.updateGroupsWithAttribute = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_GROUPS_WITH_ATTRIBUTE_ERROR(
    state: AdminState,
    error: AxiosError
  ): void {
    state.apiState.updateGroupsWithAttribute = {
      ...defaultApiState,
      error
    };
  },

  GET_SPECS_BY_ATTRIBUTE_ID_LOADING(state: AdminState): void {
    state.apiState.getSpecsByAttributeId = {
      ...defaultApiState,
      loading: true
    };
    state.attributeSpecs = undefined;
  },
  GET_SPECS_BY_ATTRIBUTE_ID_SUCCESS(
    state: AdminState,
    data: MyjbiGroupUserAttributeSpec[]
  ): void {
    state.apiState.getSpecsByAttributeId = {
      ...defaultApiState,
      success: true
    };
    state.attributeSpecs = data;
  },
  GET_SPECS_BY_ATTRIBUTE_ID_ERROR(state: AdminState, error: AxiosError): void {
    state.apiState.getSpecsByAttributeId = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_USER_ATTRIBUTES_LOADING(state: AdminState) {
    state.apiState.getGroupUserAttributes = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributes = undefined;
  },
  GET_GROUP_USER_ATTRIBUTES_SUCCESS(
    state: AdminState,
    data: MyjbiGroupUserAttribute[]
  ) {
    state.apiState.getGroupUserAttributes = {
      ...defaultApiState,
      success: true
    };
    state.groupUserAttributes = data;
  },
  GET_GROUP_USER_ATTRIBUTES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupUserAttributes = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.createGroupTemplate = {
      ...defaultApiState,
      loading: true
    };
    state.groupTemplateById = undefined;
  },
  CREATE_GROUP_TEMPLATE_SUCCESS(state: AdminState, data: GroupTemplate) {
    state.apiState.createGroupTemplate = {
      ...defaultApiState,
      success: true
    };
    state.groupTemplateById = data;
  },
  CREATE_GROUP_TEMPLATE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.createGroupTemplate = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.updateGroupTemplateApi = {
      ...defaultApiState,
      loading: true
    };
    state.groupTemplateById = undefined;
  },
  UPDATE_GROUP_TEMPLATE_SUCCESS(state: AdminState, data: GroupTemplate) {
    state.apiState.updateGroupTemplateApi = {
      ...defaultApiState,
      success: true
    };
    state.groupTemplateById = data;
  },
  UPDATE_GROUP_TEMPLATE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.updateGroupTemplateApi = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.getGroupTemplates = {
      ...defaultApiState,
      loading: true
    };
    state.groupTemplates = undefined;
  },
  GET_GROUP_TEMPLATE_SUCCESS(
    state: AdminState,
    data: Pagination<GroupTemplate>
  ) {
    state.apiState.getGroupTemplates = {
      ...defaultApiState,
      success: true
    };
    state.groupTemplates = data;
  },
  GET_GROUP_TEMPLATE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getGroupTemplates = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.getGroupLevelAttributeTemplates = {
      ...defaultApiState,
      loading: true
    };
    state.groupLevelAttributeTemplates = undefined;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_SUCCESS(
    state: AdminState,
    data: Pagination<GroupLevelAttributeTemplate>
  ) {
    state.apiState.getGroupLevelAttributeTemplates = {
      ...defaultApiState,
      success: true
    };
    state.groupLevelAttributeTemplates = data;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getGroupLevelAttributeTemplates = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_BY_ID_LOADING(state: AdminState) {
    state.apiState.getGroupLevelAttributeTemplateById = {
      ...defaultApiState,
      loading: true
    };
    state.groupLevelAttributeTemplate = undefined;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_BY_ID_SUCCESS(
    state: AdminState,
    data: GroupLevelAttributeTemplate
  ) {
    state.apiState.getGroupLevelAttributeTemplateById = {
      ...defaultApiState,
      success: true
    };
    state.groupLevelAttributeTemplate = data;
  },
  GET_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_BY_ID_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getGroupLevelAttributeTemplateById = {
      ...defaultApiState,
      error
    };
  },

  DELETE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.deleteGroupLevelAttributeTemplate = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_SUCCESS(state: AdminState) {
    state.apiState.deleteGroupLevelAttributeTemplate = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.deleteGroupLevelAttributeTemplate = {
      ...defaultApiState,
      error
    };
  },

  CREATE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.createGroupLevelAttributeTemplate = {
      ...defaultApiState,
      loading: true
    };
    state.newGroupLevelAttributeTemplate = undefined;
  },
  CREATE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_SUCCESS(
    state: AdminState,
    data: CreateGroupLevelAttributeTemplateResponse
  ) {
    state.apiState.createGroupLevelAttributeTemplate = {
      ...defaultApiState,
      success: true
    };
    state.newGroupLevelAttributeTemplate = data;
  },
  CREATE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.createGroupLevelAttributeTemplate = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.updateGroupLevelAttributeTemplate = {
      ...defaultApiState,
      loading: true
    };
    state.updatedGroupLevelAttributeTemplate = undefined;
  },
  UPDATE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_SUCCESS(
    state: AdminState,
    data: UpdateGroupLevelAttributeTemplateResponse
  ) {
    state.apiState.updateGroupLevelAttributeTemplate = {
      ...defaultApiState,
      success: true
    };
    state.updatedGroupLevelAttributeTemplate = data;
  },
  UPDATE_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.updateGroupLevelAttributeTemplate = {
      ...defaultApiState,
      error
    };
  },

  VERIFY_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_NAME_LOADING(state: AdminState) {
    state.apiState.verifyGroupLevelAttributeTemplateName = {
      ...defaultApiState,
      loading: true
    };
    state.verifyGroupLevelAttributeTemplateName = undefined;
  },
  VERIFY_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_NAME_SUCCESS(
    state: AdminState,
    data: boolean
  ) {
    state.apiState.verifyGroupLevelAttributeTemplateName = {
      ...defaultApiState,
      success: true
    };
    state.verifyGroupLevelAttributeTemplateName = data;
  },
  VERIFY_GROUP_LEVEL_ATTRIBUTE_TEMPLATE_NAME_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.verifyGroupLevelAttributeTemplateName = {
      ...defaultApiState,
      error
    };
  },

  DELETE_GROUP_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.deleteGroupTemplate = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_TEMPLATE_SUCCESS(state: AdminState) {
    state.apiState.deleteGroupTemplate = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_TEMPLATE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.deleteGroupTemplate = {
      ...defaultApiState,
      error
    };
  },

  CHECK_GROUP_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.verifyGroupTemplate = {
      ...defaultApiState,
      loading: true
    };
    state.verifyGroupTemplate = undefined;
  },
  CHECK_GROUP_TEMPLATE_SUCCESS(state: AdminState, data: boolean) {
    state.apiState.verifyGroupTemplate = {
      ...defaultApiState,
      success: true
    };
    state.verifyGroupTemplate = data;
  },
  CHECK_GROUP_TEMPLATE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.verifyGroupTemplate = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_USER_ATTRIBUTE_UPLOAD_IMAGE_SIGNED_URL_LOADING(state: AdminState) {
    state.apiState.getGroupUserAttributeUploadImageSignedUrl = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributeUploadImageSignedUrl = undefined;
  },
  GET_GROUP_USER_ATTRIBUTE_UPLOAD_IMAGE_SIGNED_URL_SUCCESS(
    state: AdminState,
    data: GetUploadImageSignedUrlResponse
  ) {
    state.apiState.getGroupUserAttributeUploadImageSignedUrl = {
      ...defaultApiState,
      success: true
    };
    state.groupUserAttributeUploadImageSignedUrl = data;
  },
  GET_GROUP_USER_ATTRIBUTE_UPLOAD_IMAGE_SIGNED_URL_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getGroupUserAttributeUploadImageSignedUrl = {
      ...defaultApiState,
      error
    };
  },

  GET_CURRENT_USER_UPLOAD_IMAGE_SIGNED_URL_LOADING(state: AdminState) {
    state.apiState.getCurrentUserUploadImageSignedUrl = {
      ...defaultApiState,
      loading: true
    };
    state.currentUserUploadImageSignedUrl = undefined;
  },
  GET_CURRENT_USER_UPLOAD_IMAGE_SIGNED_URL_SUCCESS(
    state: AdminState,
    data: GetUploadImageSignedUrlResponse
  ) {
    state.apiState.getCurrentUserUploadImageSignedUrl = {
      ...defaultApiState,
      success: true
    };
    state.currentUserUploadImageSignedUrl = data;
  },
  GET_CURRENT_USER_UPLOAD_IMAGE_SIGNED_URL_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getCurrentUserUploadImageSignedUrl = {
      ...defaultApiState,
      error
    };
  },

  GET_USER_GROUPS_LOADING(state: AdminState) {
    state.apiState.getUserGroupsByUserId = {
      ...defaultApiState,
      loading: true
    };
    state.getUserGroups = undefined;
  },
  GET_USER_GROUPS_SUCCESS(state: AdminState, data: object) {
    state.apiState.getUserGroupsByUserId = {
      ...defaultApiState,
      success: true
    };
    state.getUserGroups = data;
  },
  GET_USER_GROUPS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getUserGroupsByUserId = {
      ...defaultApiState,
      error
    };
  },

  GET_GROUP_USER_ATTRIBUTE_LIST_LOADING(state: AdminState): void {
    state.apiState.getGroupUserAttributeList = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributeList = undefined;
  },
  GET_GROUP_USER_ATTRIBUTE_LIST_SUCCESS(
    state: AdminState,
    data: GetGroupUserAttributeListResponse
  ): void {
    state.apiState.getGroupUserAttributeList = {
      ...defaultApiState,
      success: true
    };
    state.groupUserAttributeList = data;
  },
  GET_GROUP_USER_ATTRIBUTE_LIST_ERROR(
    state: AdminState,
    error: AxiosError
  ): void {
    state.apiState.getGroupUserAttributeList = {
      ...defaultApiState,
      error
    };
  },

  GET_CURRENT_USER_ATTRIBUTES_LOADING(state: AdminState) {
    state.apiState.getCurrentUserAttributes = {
      ...defaultApiState,
      success: true
    };
    state.currentUserAttributes = undefined;
  },
  GET_CURRENT_USER_ATTRIBUTES_SUCCESS(state: AdminState, data: []): void {
    state.apiState.getCurrentUserAttributes = {
      ...defaultApiState,
      success: true
    };
    state.currentUserAttributes = data;
  },
  GET_CURRENT_USER_ATTRIBUTES_ERROR(
    state: AdminState,
    error: AxiosError
  ): void {
    state.apiState.getCurrentUserAttributes = {
      ...defaultApiState,
      error
    };
  },

  UPDATE_CURRENT_USER_ATTRIBUTE_VALUES_LOADING(state: AdminState) {
    state.apiState.updateCurrentUserAttributeValues = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_CURRENT_USER_ATTRIBUTE_VALUES_SUCCESS(state: AdminState) {
    state.apiState.updateCurrentUserAttributeValues = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_CURRENT_USER_ATTRIBUTE_VALUES_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.updateCurrentUserAttributeValues = {
      ...defaultApiState,
      error
    };
  },

  GET_ALL_COUNTRIES_LOADING(state: AdminState) {
    state.apiState.getCountries = {
      ...defaultApiState,
      loading: true
    };
    state.countries = undefined;
  },
  GET_ALL_COUNTRIES_SUCCESS(state: AdminState, data: AddressCountry[]) {
    state.apiState.getCountries = {
      ...defaultApiState,
      success: true
    };
    state.countries = data;
  },
  GET_ALL_COUNTRIES_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getCountries = {
      ...defaultApiState,
      error
    };
  },

  GET_COUNTRY_BY_ID_LOADING(state: AdminState) {
    state.apiState.getCountryById = {
      ...defaultApiState,
      loading: true
    };
    state.countryById = undefined;
  },
  GET_COUNTRY_BY_ID_SUCCESS(state: AdminState, data: AddressCountry) {
    state.apiState.getCountryById = {
      ...defaultApiState,
      success: true
    };
    state.countryById = data;
  },
  GET_COUNTRY_BY_ID_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getCountryById = {
      ...defaultApiState,
      error
    };
  },

  GET_STATES_BY_COUNTRY_ID_LOADING(state: AdminState) {
    state.apiState.getStatesByCountryId = {
      ...defaultApiState,
      loading: true
    };
    state.statesByCountryId = undefined;
  },
  GET_STATES_BY_COUNTRY_ID_SUCCESS(state: AdminState, data: AddressState[]) {
    state.apiState.getStatesByCountryId = {
      ...defaultApiState,
      success: true
    };
    state.statesByCountryId = data;
  },
  GET_STATES_BY_COUNTRY_ID_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getStatesByCountryId = {
      ...defaultApiState,
      error
    };
  },

  /**
   * user attribute template mutations
   */
  GET_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.getGroupUserAttributeTemplates = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributeTemplates = undefined;
  },
  GET_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS(
    state: AdminState,
    data: Pagination<GroupUserAttributeTemplateUpStream>
  ) {
    state.apiState.getGroupUserAttributeTemplates = {
      ...defaultApiState,
      success: true
    };
    state.groupUserAttributeTemplates = data;
  },
  GET_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.getGroupUserAttributeTemplates = {
      ...defaultApiState,
      error
    };
  },
  VERIFY_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState): void {
    state.apiState.verifyGroupUserAttributeTemplateName = {
      ...defaultApiState,
      loading: true
    };
    state.groupUserAttributeTemplateExisted = undefined;
  },
  VERIFY_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS(
    state: AdminState,
    data: boolean
  ): void {
    state.groupUserAttributeTemplateExisted = data;
    state.apiState.verifyGroupUserAttributeTemplateName = {
      ...defaultApiState,
      success: true
    };
  },
  VERIFY_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ): void {
    state.groupUserAttributeTemplateExisted = undefined;
    state.apiState.verifyGroupUserAttributeTemplateName = {
      ...defaultApiState,
      error
    };
  },
  CREATE_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.newGroupUserAttributeTemplateId = undefined;
    state.apiState.createGroupUserAttributeTemplate = {
      ...defaultApiState,
      loading: true
    };
  },
  CREATE_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS(
    state: AdminState,
    data: CreateGroupUserAttributeTemplateUpStream
  ) {
    state.newGroupUserAttributeTemplateId =
      data.group_user_attribute_template.id;
    state.apiState.createGroupUserAttributeTemplate = {
      ...defaultApiState,
      success: true
    };
  },
  CREATE_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.createGroupUserAttributeTemplate = {
      ...defaultApiState,
      error
    };
  },
  DELETE_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.deleteGroupUserAttributeTemplate = {
      ...defaultApiState,
      loading: true
    };
  },
  DELETE_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS(state: AdminState) {
    state.apiState.deleteGroupUserAttributeTemplate = {
      ...defaultApiState,
      success: true
    };
  },
  DELETE_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.deleteGroupUserAttributeTemplate = {
      ...defaultApiState,
      error
    };
  },
  UPDATE_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING(state: AdminState) {
    state.apiState.updateGroupUserAttributeTemplate = {
      ...defaultApiState,
      loading: true
    };
  },
  UPDATE_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS(state: AdminState) {
    state.apiState.updateGroupUserAttributeTemplate = {
      ...defaultApiState,
      success: true
    };
  },
  UPDATE_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR(
    state: AdminState,
    error: AxiosError
  ) {
    state.apiState.updateGroupUserAttributeTemplate = {
      ...defaultApiState,
      error
    };
  },
  GET_FILTERED_USERS_LOADING(state: AdminState) {
    state.apiState.getFilterUsers = {
      ...defaultApiState,
      loading: true
    };
    state.filteredUsers = undefined;
  },
  GET_FILTERED_USERS_SUCCESS(state: AdminState, payload: User[]) {
    state.apiState.getFilterUsers = {
      ...defaultApiState,
      success: true
    };
    state.filteredUsers = payload;
  },
  GET_FILTERED_USERS_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getFilterUsers = {
      ...defaultApiState,
      error
    };
  },
  GET_GROUPS_BY_NAME_AND_TYPE_LOADING(state: AdminState) {
    state.apiState.getFilterGroups = {
      ...defaultApiState,
      loading: true
    };
    state.filteredGroups = undefined;
  },
  GET_GROUPS_BY_NAME_AND_TYPE_SUCCESS(state: AdminState, payload: Group[]) {
    state.apiState.getFilterGroups = {
      ...defaultApiState,
      success: true
    };
    state.filteredGroups = payload;
  },
  GET_GROUPS_BY_NAME_AND_TYPE_ERROR(state: AdminState, error: AxiosError) {
    state.apiState.getFilterGroups = {
      ...defaultApiState,
      error
    };
  },
  GET_GROUPS_BY_USER_ATTRIBUTE_LOADING(state: AdminState) {
    state.apiState.getGroupsByUserAttribute = {
      loading: true,
      success: false,
      error: null
    };
  },
  GET_GROUPS_BY_USER_ATTRIBUTE_SUCCESS(
    state: AdminState,
    payload: GetGroupsResponsePayload
  ) {
    state.groupsByUserAttribute = payload;
    state.apiState.getGroupsByUserAttribute = {
      loading: false,
      success: true,
      error: null
    };
  },
  GET_GROUPS_BY_USER_ATTRIBUTE_ERROR(state: AdminState, error: any) {
    state.apiState.getGroupsByUserAttribute = {
      loading: false,
      success: false,
      error
    };
  }
};
