import { defaultApiState } from '@/store/types/general.types';
import { AdminState } from './types/admin.types';

export const adminState: AdminState = {
  apiState: {
    getGroupsByUserAttribute: defaultApiState,
    inviteNewUserToProduct: defaultApiState,
    bulkInviteNewUserToProduct: defaultApiState,
    generateInvitationLinks: defaultApiState,
    getUsers: defaultApiState,
    toggleUser: defaultApiState,
    verifyUser: defaultApiState,
    removeUserInvitation: defaultApiState,
    removeInvitation: defaultApiState,
    getUserInvitations: defaultApiState,
    resendUserInvitation: defaultApiState,
    getTermsAndConditions: defaultApiState,
    getTermsAndConditionsApplication: defaultApiState,
    getTermsAndConditionsHistory: defaultApiState,
    addTermsAndConditions: defaultApiState,
    updateTermsAndConditions: defaultApiState,
    deleteTermsAndConditions: defaultApiState,
    setDefaultTermsAndConditions: defaultApiState,
    getInvitations: defaultApiState,
    getSubscriptions: defaultApiState,
    updateSubscriptionStatus: defaultApiState,
    createNewGroup: defaultApiState,
    getGroups: defaultApiState,
    getSpecificGroups: defaultApiState,
    getSimplifiedGroups: defaultApiState,
    getGroupsFromAttribute: defaultApiState,
    getSubGroups: defaultApiState,
    getGroupAttributeTypes: defaultApiState,
    getProtectedGroupTypes: defaultApiState,
    getGroupDetail: defaultApiState,
    getParentGroupDetailByParentGroupId: defaultApiState,
    getParentGroupDetailByChildGroupId: defaultApiState,
    addMembersToGroup: defaultApiState,
    editGroupMember: defaultApiState,
    updateGroup: defaultApiState,
    deleteGroupMember: defaultApiState,
    deleteGroup: defaultApiState,
    createGroupProduct: defaultApiState,
    getGroupProducts: defaultApiState,
    updateGroupProduct: defaultApiState,
    createGroupLicense: defaultApiState,
    getGroupStats: defaultApiState,
    getGroupLicenses: defaultApiState,
    updateGroupLicense: defaultApiState,
    deleteGroupLicense: defaultApiState,
    resendEmailNotificationToGroupMember: defaultApiState,
    exportMembersListToCsv: defaultApiState,
    exportDeleteMembersListToCsv: defaultApiState,
    uploadUserListFile: defaultApiState,
    validateSingleUserProduct: defaultApiState,
    removeEmailDomain: defaultApiState,
    getMasterGroupEmailDomains: defaultApiState,
    getGroupAttributeOrder: defaultApiState,
    getThirdPartyUsers: defaultApiState,
    createThirdPartyUser: defaultApiState,
    getGroupTypes: defaultApiState,
    groupType: defaultApiState,
    updateThirdPartyUser: defaultApiState,
    getMembersCountByGroupId: defaultApiState,
    getUserByEmail: defaultApiState,
    getUserByAdmin: defaultApiState,
    updateUserByAdmin: defaultApiState,
    getGroupLevelAttributeTypes: defaultApiState,
    getAllGroupLevelAttributes: defaultApiState,
    getPaginatedGroupLevelAttributes: defaultApiState,
    getDefaultGroupLevelAttributes: defaultApiState,
    getGroupLevelAttributeById: defaultApiState,
    createGroupLevelAttribute: defaultApiState,
    updateGroupLevelAttribute: defaultApiState,
    verifyGroupLevelAttribute: defaultApiState,
    getGroupUserAttributeById: defaultApiState,
    getGroupLevelAttributeValuesByGroupId: defaultApiState,
    createGroupLevelAttributeValues: defaultApiState,
    updateGroupLevelAttributeValues: defaultApiState,
    getMembers: defaultApiState,
    getGroupLevelAttributeTemplates: defaultApiState,
    getGroupLevelAttributeTemplateById: defaultApiState,
    createGroupLevelAttributeTemplate: defaultApiState,
    updateGroupLevelAttributeTemplate: defaultApiState,
    deleteGroupLevelAttributeTemplate: defaultApiState,
    verifyGroupLevelAttributeTemplateName: defaultApiState,
    createGroupTemplate: defaultApiState,
    getGroupTemplates: defaultApiState,
    getGroupTemplateById: defaultApiState,
    updateGroupTemplateApi: defaultApiState,
    deleteGroupTemplate: defaultApiState,
    verifyGroupTemplate: defaultApiState,
    verifyGroupUserAttribute: defaultApiState,
    createGroupUserAttribute: defaultApiState,
    updateGroupUserAttribute: defaultApiState,
    deleteGroupUserAttribute: defaultApiState,
    updateGroupsWithAttribute: defaultApiState,
    getSpecsByAttributeId: defaultApiState,
    getGroupUserAttributeTypes: defaultApiState,
    getGroupUserAttributes: defaultApiState,
    getGroupUserAttributeUploadImageSignedUrl: defaultApiState,
    getCurrentUserUploadImageSignedUrl: defaultApiState,
    getUserGroupsByUserId: defaultApiState,
    getGroupUserAttributeList: defaultApiState,
    getCurrentUserAttributes: defaultApiState,
    updateCurrentUserAttributeValues: defaultApiState,
    getCountries: defaultApiState,
    getCountryById: defaultApiState,
    getStatesByCountryId: defaultApiState,
    getGroupUserAttributeTemplates: defaultApiState,
    verifyGroupUserAttributeTemplateName: defaultApiState,
    createGroupUserAttributeTemplate: defaultApiState,
    deleteGroupUserAttributeTemplate: defaultApiState,
    updateGroupUserAttributeTemplate: defaultApiState,
    getFilterUsers: defaultApiState,
    getFilterGroups: defaultApiState
  },
  groupsByUserAttribute: undefined,
  invitations: undefined,
  subscriptions: undefined,
  userInvitations: undefined,
  userDetails: undefined,
  termsAndConditionsPayload: undefined,
  termsAndConditionsApplicationPayload: undefined,
  termsAndConditionsHistoryPayload: undefined,
  groups: undefined,
  specificGroups: undefined,
  simplifiedGroups: undefined,
  groupsFromAttribute: undefined,
  subGroups: undefined,
  protectedGroupTypes: undefined,
  groupDetail: undefined,
  parentGroupDetailByParentGroupId: undefined,
  parentGroupDetailByChildGroupId: undefined,
  groupProducts: undefined,
  groupStats: undefined,
  groupLicenses: undefined,
  uploadUserListFile: undefined,
  validateSingleUserProduct: undefined,
  groupProductsNameList: undefined,
  groupList: undefined,
  groupLevelAttributeValuesByGroupId: undefined,
  masterGroupEmailDomains: undefined,
  groupAttributeOrder: undefined,
  thirdPartyUsers: undefined,
  groupTypes: undefined,
  userByEmailResponse: undefined,
  userProfile: null,
  groupLevelAttributeTypes: undefined,
  groupLevelAttribute: undefined,
  paginatedGroupLevelAttributes: undefined,
  allGroupLevelAttributes: undefined,
  defaultGroupLevelAttributes: undefined,
  newGroupLevelAttribute: undefined,
  updatedGroupLevelAttribute: undefined,
  verifyGroupLevelAttribute: undefined,
  members: undefined,
  groupLevelAttributeTemplates: undefined,
  groupLevelAttributeTemplate: undefined,
  verifyGroupLevelAttributeTemplateName: undefined,
  newGroupLevelAttributeTemplate: undefined,
  updatedGroupLevelAttributeTemplate: undefined,
  groupTemplates: undefined,
  groupTemplateById: undefined,
  verifyGroupTemplate: undefined,
  verifyGroupUserAttribute: undefined,
  groupUserAttributeTypes: undefined,
  groupUserAttributeById: undefined,
  groupUserAttributes: undefined,
  attributeSpecs: undefined,
  newGroupUserAttribute: undefined,
  updatedGroupUserAttribute: undefined,
  groupUserAttributeUploadImageSignedUrl: undefined,
  currentUserUploadImageSignedUrl: undefined,
  getUserGroups: undefined,
  groupUserAttributeList: undefined,
  currentUserAttributes: undefined,
  countries: undefined,
  countryById: undefined,
  statesByCountryId: undefined,
  groupUserAttributeTemplates: undefined,
  newGroupUserAttributeTemplateId: undefined,
  groupUserAttributeTemplateExisted: undefined,
  filteredUsers: undefined,
  filteredGroups: undefined
};
