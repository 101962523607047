import { ApplicationState } from '@/store/modules/application/types/application.types';
import { ModuleTreeState } from '@/store/modules/module-tree/types/module-tree.types';
import { RoleState } from '@/store/modules/role/types/role.types';
import { SumariState } from '@/store/modules/sumari/sumari.types';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import admin from './modules/admin/admin.module';
import { AdminState } from './modules/admin/types/admin.types';
import { UserAttributeState } from './modules/admin/types/group-user-attribute.types';
import application from './modules/application/application.module';
import auditLog from './modules/audit-log/audit-log.module';
import { AuditLogState } from './modules/audit-log/types/audit-log.types';
import auth from './modules/auth/auth.module';
import { AuthState } from './modules/auth/types/auth.types';
import { content } from './modules/content';
import { ContentState } from './modules/content/content.state';
import { events } from './modules/events';
import { EventsState } from './modules/events/events.state';
import jobResultData from './modules/job-result-data/job-result-data.module';
import { JobResultDataState } from './modules/job-result-data/job-result-data.types';
import { JournalState } from './modules/journal';
import journal from './modules/journal/journal.module';
import moduleTree from './modules/module-tree/module-tree.module';
import { OrderState } from './modules/order';
import order from './modules/order/order.module';
import profile from './modules/profile/profile.module';
import { ProfileState } from './modules/profile/profile.state';
import { RbacState } from './modules/rbac';
import rbac from './modules/rbac/rbac.module';
import role from './modules/role/role.module';
import rolesAndPermissions from './modules/roles-and-permissions/roles-and-permissions.module';
import { RolesAndPermissionsState } from './modules/roles-and-permissions/types/roles-and-permissions.types';
import staticFile from './modules/static-file/static-file.module';
import { StaticApiState } from './modules/static-file/types/static-file.types';
import subscription from './modules/subscription/subscription.module';
import { SubscriptionState } from './modules/subscription/subscription.state';
import sumari from './modules/sumari/sumari.module';
import userAttribute from './modules/user-attribute/user-attribute.module';
import { userGroups } from './modules/user-groups';
import { UserGroupsState } from './modules/user-groups/user-groups.state';
import { WebSocketState } from './modules/websocket/types/websocket.types';
import websocket from './modules/websocket/websocket.module';
import { forbiddenResponseHandlerPlugin } from './plugins/forbidden-response-handler.plugin';
Vue.use(Vuex);

// tslint:disable-next-line
export interface RootState {
  auth: AuthState;
  admin: AdminState;
  staticFile: StaticApiState;
  profile: ProfileState;
  subscription: SubscriptionState;
  application: ApplicationState;
  journal: JournalState;
  order: OrderState;
  rbac: RbacState;
  sumari: SumariState;
  auditLog: AuditLogState;
  websocket: WebSocketState;
  userAttribute: UserAttributeState;
  moduleTree: ModuleTreeState;
  role: RoleState;
  rolesAndPermissions: RolesAndPermissionsState;
  jobResultData: JobResultDataState;
  userGroups: UserGroupsState;
  events: EventsState;
  content: ContentState;
}

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    profile,
    subscription,
    application,
    journal,
    order,
    rbac,
    admin,
    sumari,
    staticFile,
    auditLog,
    websocket,
    userAttribute,
    moduleTree,
    role,
    rolesAndPermissions,
    jobResultData,
    userGroups,
    events,
    content
  },
  mutations: {},
  actions: {},
  plugins: [
    forbiddenResponseHandlerPlugin,
    createPersistedState({ paths: ['rbac.roles'] })
  ]
}) as Store<RootState>;
