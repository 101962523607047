import { MutationTree } from 'vuex';
import { Event, EventsState } from './events.state';

export enum EventsMutations {
  SET_EVENTS = 'SET_EVENTS',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR'
}

export const mutations: MutationTree<EventsState> = {
  [EventsMutations.SET_EVENTS](state, events: Event[]) {
    state.events = events;
  },
  [EventsMutations.SET_LOADING](state, loading: boolean) {
    state.apiState.getEvents.loading = loading;
  },
  [EventsMutations.SET_ERROR](state, error: boolean) {
    state.apiState.getEvents.error = error;
  }
};
