export interface ContentItem {
  id: string;
  title: string;
  field_by_line?: string;
  description?: string;
  field_link?: string;
  articleLink?: string;
  field_image?: string;
  field_country?: string;
  field_date?: string;
  field_teaser_image?: string;
  articleImageUrl?: string;
  teaserImageUrl?: string;
  location?: string;
  date?: string;
  // JBI Buzz specific fields
  issue?: string;
  link?: string;
  image?: string;
}

export interface ContentState {
  shortCourses: ContentItem[];
  ebpResources: ContentItem[];
  news: ContentItem[];
  newsBuzz: ContentItem[];
  apiState: {
    shortCourses: {
      loading: boolean;
      error: boolean;
    };
    ebpResources: {
      loading: boolean;
      error: boolean;
    };
    news: {
      loading: boolean;
      error: boolean;
    };
    newsBuzz: {
      loading: boolean;
      error: boolean;
    };
  };
}

export const state: ContentState = {
  shortCourses: [],
  ebpResources: [],
  news: [],
  newsBuzz: [],
  apiState: {
    shortCourses: {
      loading: false,
      error: false
    },
    ebpResources: {
      loading: false,
      error: false
    },
    news: {
      loading: false,
      error: false
    },
    newsBuzz: {
      loading: false,
      error: false
    }
  }
};
