import { RootState } from '@/store/store';
import { Module } from 'vuex';
import { actions } from './user-groups.actions';
import { mutations } from './user-groups.mutations';
import { state, UserGroupsState } from './user-groups.state';

const namespaced = true;

export const userGroups: Module<UserGroupsState, RootState> = {
  namespaced,
  state,
  mutations,
  actions
};
