import {
  CreateGroupLevelAttributeDTO,
  CreateGroupLevelAttributeTemplateDTO,
  InsertGroupLevelAttributeValueDTO,
  UpdateGroupLevelAttributeDTO,
  UpdateGroupLevelAttributeTemplateDTO,
  UpdateGroupLevelAttributeValueDTO
} from '@/jbi-shared/dto/jaas-group-level-attributes.dtos';
import {
  AddTermsAndConditionPayload,
  BulkInviteUserToProductPayload,
  ExportDeleteGroupMembersCsvPayload,
  ExportGroupMembersCsvPayload,
  FilteredGroupPayload,
  FilteredSubGroupPayload,
  GetFilteredGroupListPayload,
  GetFilteredUserListPayload,
  GetGroupProductsPayload,
  GetGroupsResponsePayload,
  GetInvitationsPayload,
  GetInvitedUserPayload,
  GetMembersResponsePayload,
  GetProtectedGroupTypesResponsePayload,
  GetSubGroupsResponsePayload,
  GetTermsAndConditionsPayload,
  GroupAttributeOrder,
  GroupType,
  InviteUserToProductPayload,
  UpdateTermsAndConditionPayload,
  ValidateUserInvited,
  ValidateUserListPayload
} from '@/store/modules/admin/types/admin.types';
import { GetGroupTypesPayload } from '@/store/modules/admin/types/group-type.types';
import {
  BulkUpdateMemberGroupUserAttributeValuePayload,
  GetGroupUserAttributeListPayload,
  GetUploadImageSignedUrlPayload,
  GroupUserAttributePayload,
  GroupUserAttributeWithGroupCount,
  UpdateGroupsUserAttributePayload,
  UpdateGroupsWithAttributePayload
} from '@/store/modules/admin/types/group-user-attribute.types';
import { ProfileUpdatePayload } from '@/store/modules/profile/profile.state';
import retry from 'async-retry';
import { AxiosPromise } from 'axios';
import { GROUP_LISTING_VIEW } from '../jbi-shared/types/myjbi-group.types';
import {
  AddressFilterOption,
  FilteredGroupLevelAttributesPayload,
  FilteredGroupTemplatesPayload,
  GroupTemplatePayload
} from '../store/modules/admin/types/group-level-attribute.types';
import {
  CreateGroupLicenseRequestPayload,
  GetGroupLicensePayload,
  GetGroupsLicenseResponsePayload,
  UpdateGroupLicenseRequestPayload
} from '../store/modules/admin/types/group-license.types';
import {
  CreateGroupProductRequestPayload,
  UpdateGroupProductRequestPayload
} from '../store/modules/admin/types/group-product.types';
import {
  GetGroupUserAttributeTemplatePayload,
  NewGroupUserAttributeTemplatePayload,
  UpdateGroupUserAttributeTemplatePayload
} from '../store/modules/admin/types/group-user-attribute-template.types';
import {
  AddMembersToGroupRequestPayload,
  CreateNewGroupRequestPayload,
  CreateNewGroupResponsePayload,
  DeleteGroupMemberRequestPayload,
  FilteredGroupMemberParamsPayload,
  ResendEmailNotificationToGroupMemberPayload,
  UpdateGroupMemberRequestPayload,
  UpdateGroupRequestPayload
} from '../store/modules/admin/types/group.types';
import {
  GetSubscriptionsPayload,
  UpdateUserSubscriptionStatusPayload
} from '../store/modules/admin/types/user-subscription.types';
import {
  CreateThirdPartyUserPayload,
  GetThirdPartyUsersRequestPayload,
  UpdateThirdPartyUserPayload
} from '../views/AdminThirdPartyManagement/types/admin-third-party-management.types';
import { axios } from './axios';

export const adminApi = {
  getUsers({
    username,
    firstName,
    lastName,
    email,
    selectedStatus,
    userType,
    sortColumn,
    sortOrder,
    limit = 50,
    page = 0
  }: GetInvitedUserPayload) {
    return axios.get('users', {
      params: {
        username,
        firstName,
        lastName,
        email,
        selectedStatus,
        userType,
        sortColumn,
        sortOrder,
        page,
        limit
      }
    });
  },
  inviteNewUserToProduct(payload: InviteUserToProductPayload) {
    return axios.post(`invitations`, {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      productId: payload.productId
    });
  },
  bulkInviteNewUserToProduct({ invitations }: BulkInviteUserToProductPayload) {
    return axios.post(`invitations/bulk-invite`, {
      invitations
    });
  },
  generateInvitationLinks({ invitations }: BulkInviteUserToProductPayload) {
    return axios.post(`invitations/generate`, {
      invitations
    });
  },
  toggleUser(userId: number, email: string) {
    return axios.post(`users/toggle`, {
      userId,
      email
    });
  },
  verifyUser(userId: number) {
    return axios.patch(`users/${userId}/toggle-verification`);
  },
  removeInvitation(id: number) {
    return axios.delete(`invitations/${id}`);
  },
  resendUserInvitation(id: number, email: string) {
    return axios.post(`invitations/${id}/resend`, { email });
  },
  getUserInvitations(email: string) {
    return axios.get(`invitations`, {
      params: { email }
    });
  },
  getInvitations(params: GetInvitationsPayload) {
    return axios.get('invitations', {
      params
    });
  },
  getSubscriptions(params: GetSubscriptionsPayload) {
    return axios.get('subscriptions', {
      params
    });
  },
  getTermsAndConditions(params: GetTermsAndConditionsPayload) {
    return axios.get('/tnc', {
      params
    });
  },
  getTermsAndConditionsByAppID(appId: string) {
    return axios.get(`/tnc/${appId}/application`);
  },
  getTermsAndConditionsHistory(tncId: number) {
    return axios.get(`/tnc/${tncId}/history`);
  },
  addTermsAndConditions(payload: AddTermsAndConditionPayload) {
    return axios.post('/tnc', { ...payload });
  },
  updateTermsAndConditions(payload: UpdateTermsAndConditionPayload) {
    return axios.post(`/tnc/${payload.id}/`, payload);
  },
  deleteTermsAndConditions(tncId: number) {
    return axios.delete(`/tnc/${tncId}`);
  },
  setDefaultTermsAndConditions(tncId: number) {
    return axios.put(`/tnc/${tncId}/default`);
  },
  updateSubscriptionStatus(payload: UpdateUserSubscriptionStatusPayload) {
    return axios.patch(`users-applications/${payload.id}`, {
      status: payload.status
    });
  },
  createNewGroup(payload: CreateNewGroupRequestPayload) {
    return axios.post<CreateNewGroupResponsePayload>('/groups', payload);
  },
  getGroups(params: FilteredGroupPayload) {
    return axios.get<GetGroupsResponsePayload>('/groups', {
      params: {
        view: GROUP_LISTING_VIEW.FULL,
        ...params
      }
    });
  },
  getSpecificGroups(groupIds: number[]) {
    return axios.get<GetGroupsResponsePayload>('/groups', {
      params: {
        view: GROUP_LISTING_VIEW.SPECIFIC_GROUPS,
        groupIds: groupIds.toString()
      }
    });
  },
  getSimplifiedGroups(query: string) {
    return axios.get<GetGroupsResponsePayload>('/groups', {
      params: {
        view: GROUP_LISTING_VIEW.SIMPLIFIED,
        groupname: query
      }
    });
  },
  getProtectedGroupTypes() {
    return retry(() =>
      axios.get<GetProtectedGroupTypesResponsePayload>(
        '/groups/protected-group-types'
      )
    );
  },
  getGroupDetail(id: number, isMembers: boolean) {
    return axios.get<GetGroupsResponsePayload>(`/groups/${id}`, {
      params: { isMembers }
    });
  },
  getSubGroups(id: number, params: FilteredSubGroupPayload) {
    return axios.get<GetSubGroupsResponsePayload>(`/groups/${id}/subgroups`, {
      params
    });
  },
  getMembers(groupId: number, params: FilteredGroupMemberParamsPayload) {
    return axios.get<GetMembersResponsePayload>(`/groups/${groupId}/members`, {
      params: { ...params }
    });
  },
  getParentGroupDetailByParentGroupId(id: number) {
    return this.getGroupDetail(id, true);
  },
  getMasterGroupEmailDomains(id: number) {
    return axios.get(`/groups/${id}/email-domains`);
  },
  getParentGroupDetailByChildGroupId(id: number) {
    return axios.get<GetGroupsResponsePayload>(`/groups/${id}/parents`);
  },
  addMembersToGroup(payload: AddMembersToGroupRequestPayload) {
    return axios.post(`/groups/${payload.groupId}/members`, payload);
  },
  editGroupMember(payload: UpdateGroupMemberRequestPayload) {
    return axios.put(
      `/groups/${payload.groupId}/members/${payload.userId}`,
      payload
    );
  },
  deleteGroupMember(payload: DeleteGroupMemberRequestPayload) {
    return axios.delete(`/groups/${payload.groupId}/members/${payload.userId}`);
  },
  updateGroup(payload: UpdateGroupRequestPayload) {
    return axios.put(`/groups/${payload.groupId}`, payload);
  },
  removeEmailDomain(
    groupId: number,
    emailDomain: string | null,
    notify: boolean
  ) {
    return axios.delete(`/groups/${groupId}/email-domain`, {
      data: {
        emailDomain,
        notify
      }
    });
  },
  deleteGroup(id: number) {
    return axios.delete(`/groups/${id}`);
  },
  getGroupProducts(params: GetGroupProductsPayload) {
    return axios.get<GetGroupsResponsePayload>('/group-products', { params });
  },
  createGroupProduct(payload: CreateGroupProductRequestPayload) {
    return axios.post<CreateGroupProductRequestPayload>(
      '/group-products',
      payload
    );
  },
  updateGroupProduct(payload: UpdateGroupProductRequestPayload) {
    return axios.put<UpdateGroupProductRequestPayload>(
      '/group-products/' + payload.id,
      payload
    );
  },
  createGroupLicense(payload: CreateGroupLicenseRequestPayload) {
    return axios.post<CreateGroupLicenseRequestPayload>(
      '/group-licenses',
      payload
    );
  },
  getGroupStats(isEditing?: boolean) {
    return axios.get<GetGroupsResponsePayload>('/groups', {
      params: {
        view: GROUP_LISTING_VIEW.STATS,
        isEditing
      }
    });
  },
  getGroupLicenses(params: Partial<GetGroupLicensePayload>) {
    return axios.get<GetGroupsLicenseResponsePayload>('/group-licenses', {
      params
    });
  },
  updateGroupLicense(payload: UpdateGroupLicenseRequestPayload) {
    return axios.put<UpdateGroupLicenseRequestPayload>(
      '/group-licenses/' + payload.id,
      payload
    );
  },
  deleteGroupLicense(id: number) {
    return axios.delete('/group-licenses/' + id);
  },
  resendEmailNotificationToGroupMember(
    payload: ResendEmailNotificationToGroupMemberPayload
  ) {
    return axios.post(
      `/groups/${payload.groupId}/members/${payload.userId}/email-notification`,
      payload
    );
  },
  exportCsvGroupMembersList(params: ExportGroupMembersCsvPayload) {
    return axios.post(`/groups/${params.id}/members/export`, params);
  },

  exportCsvDeleteGroupMembersList(payload: ExportDeleteGroupMembersCsvPayload) {
    return axios.post(
      `/groups/${payload.groupId}/email-domain/members/export`,
      {
        parentsName: payload.parentsName,
        emailDomain: payload.emailDomain,
        allDomains: payload.allDomains,
        isFirstDomain: payload.isFirstDomain
      }
    );
  },
  uploadUserListFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`groups/members/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  validateUserListJob(payload: ValidateUserListPayload) {
    return axios.post(`groups/members/validate`, payload);
  },
  validateUserInviteListJob(payload: ValidateUserInvited) {
    return axios.post(`invitations/validate_invited_user_list`, payload);
  },
  validateSingleUserProduct(payload: InviteUserToProductPayload) {
    return axios.post(`invitations/user/product`, payload);
  },
  getGroupAttributeOrderByGroupId(groupId: number) {
    return axios.get(`/groups/${groupId}/attribute-order`);
  },
  upsertGroupAttributeOrder(payload: GroupAttributeOrder) {
    return axios.post(`/groups/${payload.groupId}/attribute-order`, {
      attributeOrder: payload.attributeOrder
    });
  },
  createThirdPartyUser(payload: CreateThirdPartyUserPayload) {
    return axios.post(`third-party-user/create`, payload);
  },
  updateThirdPartyUser(payload: UpdateThirdPartyUserPayload) {
    return axios.put(`third-party-user/${payload.id}`, payload);
  },
  getThirdPartyUserAccess(params: GetThirdPartyUsersRequestPayload) {
    return axios.get<GetThirdPartyUsersRequestPayload>(
      '/third-party-user/list',
      {
        params
      }
    );
  },
  getGroupTypes(params?: GetGroupTypesPayload) {
    return axios.get('groups/types', {
      params
    });
  },
  editGroupTypeById(payload: GroupType) {
    return axios.put(`/groups/types/${payload.id}`, {
      name: payload.name
    });
  },
  createGroupType(payload: string) {
    return axios.post(`/groups/types`, {
      name: payload
    });
  },
  getMembersCountByGroupId(groupId: number) {
    return axios.get(`/groups/${groupId}/members-count`);
  },
  getUserByEmail(email: string) {
    return axios.get(`/users/email`, {
      params: {
        email
      }
    });
  },
  async getUserByAdmin(userId: number) {
    return await axios.get(`/admin-user/${userId}/profile`);
  },
  async updateUserByAdmin(user: any, userProfile: ProfileUpdatePayload) {
    return axios.put('/admin-user/update', {
      user,
      userProfile
    });
  },
  async updateUserProfileImageByAdmin(user: any, profileImageUrl: string) {
    return axios.post('/admin-user/update-profile-image', {
      user,
      profileImageUrl
    });
  },
  getGroupLevelAttributeTypes() {
    return axios.get(`/group-level-attributes/types`);
  },

  getAllGroupLevelAttributes() {
    return axios.get(`/group-level-attributes`);
  },

  getPaginatedGroupLevelAttributes(
    payload: FilteredGroupLevelAttributesPayload
  ) {
    return axios.get(`/group-level-attributes`, {
      params: payload
    });
  },

  getDefaultGroupLevelAttributes() {
    return axios.get(`/group-level-attributes/default/group`);
  },

  getGroupLevelAttributeById(attributeId: number) {
    return axios.get(`/group-level-attributes/${attributeId}`);
  },

  createGroupLevelAttribute(payload: CreateGroupLevelAttributeDTO) {
    return axios.post(`/group-level-attributes/create`, payload);
  },

  verifyGroupLevelAttribute(
    payload: CreateGroupLevelAttributeDTO
  ): AxiosPromise<boolean> {
    return axios.post(`/group-level-attributes/verify`, payload);
  },

  updateGroupLevelAttribute(payload: UpdateGroupLevelAttributeDTO) {
    return axios.put(`/group-level-attributes/update`, payload);
  },

  getGroupLevelAttributeValuesByGroupId(groupId: number) {
    return axios.get(`/group-level-attributes/attribute-values/${groupId}`);
  },

  createGroupLevelAttributesValues(payload: InsertGroupLevelAttributeValueDTO) {
    return axios.post(`/group-level-attributes/attribute-values`, payload);
  },

  updateGroupLevelAttributeValues(payload: UpdateGroupLevelAttributeValueDTO) {
    return axios.patch(`/group-level-attributes/attribute-values`, payload);
  },

  getGroupLevelAttributeTemplateById(templateId: number) {
    return axios.get(
      `/group-level-attributes/attribute-templates/${templateId}`
    );
  },

  getGroupLevelAttributeTemplates(payload: FilteredGroupTemplatesPayload) {
    return axios.get(`/group-level-attributes/attribute-templates`, {
      params: payload
    });
  },

  verifyGroupLevelAttributeTemplateName(name: string) {
    return axios.get(
      `/group-level-attributes/attribute-templates/verify-name`,
      {
        params: { name }
      }
    );
  },

  deleteGroupLevelAttributeTemplate(templateId: number) {
    return axios.delete(
      `/group-level-attributes/attribute-templates/${templateId}`
    );
  },

  createGroupLevelAttributeTemplate(
    payload: CreateGroupLevelAttributeTemplateDTO
  ) {
    return axios.post(`/group-level-attributes/attribute-templates`, payload);
  },

  updateGroupLevelAttributeTemplate(
    payload: UpdateGroupLevelAttributeTemplateDTO
  ) {
    return axios.patch(`/group-level-attributes/attribute-templates`, payload);
  },

  createGroupTemplate(payload: GroupTemplatePayload) {
    return axios.post(`/group-level-attributes/template`, payload);
  },

  updateGroupTemplate(payload: GroupTemplatePayload) {
    return axios.patch(`/group-level-attributes/template`, payload);
  },

  deleteGroupTemplate(templateId: number) {
    return axios.delete(`/group-level-attributes/template/${templateId}`);
  },

  getGroupTemplates(payload: FilteredGroupTemplatesPayload) {
    return axios.get(`/group-level-attributes/templates`, {
      params: payload
    });
  },

  verifyGroupTemplate(name: string) {
    return axios.get(`/group-level-attributes/verify-group-template`, {
      params: { name }
    });
  },

  getGroupUserAttributeTypes() {
    return axios.get(`/group-user-attributes/types`);
  },

  getGroupUserAttributeWithGroupCountById(
    attributeId: number
  ): AxiosPromise<GroupUserAttributeWithGroupCount> {
    return axios.get(`/group-user-attributes/${attributeId}/group-count`);
  },

  verifyGroupUserAttribute(attributeLabel: string): AxiosPromise<boolean> {
    return axios.get(`/group-user-attributes/verify`, {
      params: {
        label: attributeLabel
      }
    });
  },

  createGroupUserAttribute(payload: GroupUserAttributePayload) {
    return axios.post(`/group-user-attributes/create`, payload);
  },

  updateGroupsWithAttribute(payload: UpdateGroupsWithAttributePayload) {
    return axios.put(`/group-user-attributes/update-specs`, payload);
  },

  getSpecsByAttributeId(attributeId: number) {
    return axios.get(`/group-user-attributes/${attributeId}/specs`);
  },

  getGroupUserAttributes() {
    return axios.get(`/group-user-attributes`);
  },

  updateGroupUserAttribute(payload: UpdateGroupsUserAttributePayload) {
    return axios.put(`/group-user-attributes/update`, payload);
  },

  deleteGroupUserAttribute(attributeId: number) {
    return axios.delete(`/group-user-attributes/${attributeId}`);
  },

  async getUploadImageSignedUrl(payload: GetUploadImageSignedUrlPayload) {
    return axios.get('/group-user-attributes/upload-image-signed-url', {
      params: payload
    });
  },

  getCurrentUserUploadImageSignedUrl(payload: GetUploadImageSignedUrlPayload) {
    return axios.get('/current-user-attributes/upload-image-signed-url', {
      params: payload
    });
  },

  getGroupUserAttributeList(payload: GetGroupUserAttributeListPayload) {
    return axios.get('/group-user-attributes/attributes/group-count', {
      params: payload
    });
  },

  getCurrentUserAttributes() {
    return axios.get(`/current-user-attributes`);
  },

  updateCurrentUserAttributeValues({
    memberGroupUserAttributeValues
  }: BulkUpdateMemberGroupUserAttributeValuePayload) {
    return axios.put(`current-user-attributes`, memberGroupUserAttributeValues);
  },

  getCurrentUserAttributeValueValidity(): AxiosPromise<boolean> {
    return axios.get<boolean>('/current-user-attributes/validity');
  },

  async getUserGroupsByUserId(userId: number) {
    return axios.get(`/groups/members/${userId}`);
  },

  getCountries(payload?: AddressFilterOption) {
    return axios.get('/address/countries', {
      params: payload
    });
  },

  getCountryByCountryId(countryId: number) {
    return axios.get('/address/countries/' + countryId);
  },

  getStatesByCountryId(countryId: number) {
    return axios.get('/address/countries/' + countryId + '/states');
  },

  /**
   * user attribute template apis
   */
  getGroupUserAttributeTemplates(
    payload: GetGroupUserAttributeTemplatePayload
  ) {
    return axios.get('/group-user-attribute-templates', { params: payload });
  },
  verifyGroupUserAttributeTemplateName(templateName: string) {
    return axios.get('/group-user-attribute-templates/verify', {
      params: { templateName }
    });
  },
  createNewGroupUserAttributeTemplate(
    payload: NewGroupUserAttributeTemplatePayload
  ) {
    return axios.post('/group-user-attribute-templates', payload);
  },
  deleteGroupUserAttributeTemplate(id: number) {
    return axios.delete(`/group-user-attribute-templates/${id}`);
  },
  updateGroupUserAttributeTemplate(
    payload: UpdateGroupUserAttributeTemplatePayload
  ) {
    return axios.put(
      `/group-user-attribute-templates/${payload.templateId}`,
      payload
    );
  },

  /** Fetch filtered UserList based on email, username, firstname, lastname */
  getFilteredUserList(params: GetFilteredUserListPayload) {
    return axios.get('users/filteredList', { params });
  },

  getGroupsByUserAttribute(params: FilteredGroupPayload) {
    return axios.get<GetGroupsResponsePayload>(
      `/groups/user-attribute/groups`,
      {
        params: {
          groupname: params.groupname,
          grouptype: params.grouptype,
          limit: params.limit,
          page: params.page,
          sortColumn: params.sortColumn,
          sortOrder: params.sortOrder,
          attributeId: params.attributeId
        }
      }
    );
  },

  getGroupsByNameAndType(params: GetFilteredGroupListPayload) {
    return axios.get('/groups/filteredList', { params });
  }
};
