import { RootState } from '@/store/store';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { EventsMutations } from './events.mutations';
import { EventsState } from './events.state';

export const actions: ActionTree<EventsState, RootState> = {
  async getEvents({ commit }) {
    commit(EventsMutations.SET_LOADING, true);
    commit(EventsMutations.SET_ERROR, false);

    try {
      const response = await Vue.prototype.axios.get(
        '/api/jbi-global/collaborator-course-events'
      );
      commit(EventsMutations.SET_EVENTS, response.data);
    } catch (error) {
      commit(EventsMutations.SET_ERROR, true);
    } finally {
      commit(EventsMutations.SET_LOADING, false);
    }
  }
};
