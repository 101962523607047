import { MutationTree } from 'vuex';
import { UserGroup, UserGroupsState } from './user-groups.state';

export enum UserGroupsMutations {
  SET_USER_GROUPS = 'SET_USER_GROUPS',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR'
}

export const mutations: MutationTree<UserGroupsState> = {
  [UserGroupsMutations.SET_USER_GROUPS](state, groups: UserGroup[]) {
    state.groups = groups;
  },
  [UserGroupsMutations.SET_LOADING](state, loading: boolean) {
    state.apiState.getUserGroups.loading = loading;
  },
  [UserGroupsMutations.SET_ERROR](state, error: boolean) {
    state.apiState.getUserGroups.error = error;
  }
};
