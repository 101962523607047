import { RootState } from '@/store/store';
import Vue from 'vue';
import { ActionTree } from 'vuex';
import { ContentMutations } from './content.mutations';
import { ContentState } from './content.state';

export const actions: ActionTree<ContentState, RootState> = {
  async getShortCourses({ commit }, apiLink: string) {
    commit(ContentMutations.SET_SHORT_COURSES_LOADING, true);
    commit(ContentMutations.SET_SHORT_COURSES_ERROR, false);

    try {
      const response = await Vue.prototype.axios.get(apiLink);
      commit(ContentMutations.SET_SHORT_COURSES, response.data);
    } catch (error) {
      commit(ContentMutations.SET_SHORT_COURSES_ERROR, true);
    } finally {
      commit(ContentMutations.SET_SHORT_COURSES_LOADING, false);
    }
  },

  async getEbpResources({ commit }, apiLink: string) {
    commit(ContentMutations.SET_EBP_RESOURCES_LOADING, true);
    commit(ContentMutations.SET_EBP_RESOURCES_ERROR, false);

    try {
      const response = await Vue.prototype.axios.get(apiLink);
      commit(ContentMutations.SET_EBP_RESOURCES, response.data);
    } catch (error) {
      commit(ContentMutations.SET_EBP_RESOURCES_ERROR, true);
    } finally {
      commit(ContentMutations.SET_EBP_RESOURCES_LOADING, false);
    }
  },

  async getNews({ commit }, apiLink: string) {
    commit(ContentMutations.SET_NEWS_LOADING, true);
    commit(ContentMutations.SET_NEWS_ERROR, false);

    try {
      const response = await Vue.prototype.axios.get(apiLink);
      commit(ContentMutations.SET_NEWS, response.data);
    } catch (error) {
      commit(ContentMutations.SET_NEWS_ERROR, true);
    } finally {
      commit(ContentMutations.SET_NEWS_LOADING, false);
    }
  },

  async getNewsBuzz({ commit }, apiLink: string) {
    commit(ContentMutations.SET_NEWS_BUZZ_LOADING, true);
    commit(ContentMutations.SET_NEWS_BUZZ_ERROR, false);

    try {
      const response = await Vue.prototype.axios.get(apiLink);
      commit(ContentMutations.SET_NEWS_BUZZ, response.data);
    } catch (error) {
      commit(ContentMutations.SET_NEWS_BUZZ_ERROR, true);
    } finally {
      commit(ContentMutations.SET_NEWS_BUZZ_LOADING, false);
    }
  }
};
