export interface Event {
  id: string;
  field_title: string;
  field_event_type: string;
  field_city: string;
  field_country: string;
  field_image: string;
  field_link: string;
  field_start_date: string;
  field_end_date: string;
  field_attendance: string;
}

export interface EventsState {
  events: Event[];
  apiState: {
    getEvents: {
      loading: boolean;
      error: boolean;
    };
  };
}

export const state: EventsState = {
  events: [],
  apiState: {
    getEvents: {
      loading: false,
      error: false
    }
  }
};
