export interface UserGroup {
  id: string;
  group_name: string;
  group_type: string;
  group_logo: string;
}

export interface UserGroupsState {
  groups: UserGroup[];
  apiState: {
    getUserGroups: {
      loading: boolean;
      error: boolean;
    };
  };
}

export const state: UserGroupsState = {
  groups: [],
  apiState: {
    getUserGroups: {
      loading: false,
      error: false
    }
  }
};
