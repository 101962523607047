import { rolesAndPermissionsApi } from '@/api/roles-and-permissions.api';
import { GeneralQueryParams } from '@/store/types/general.types';
import { Store } from 'vuex';
import {
  AddGroupsToRoleDto,
  AddUsersToRoleDto,
  CreateExceptionsPayload,
  DeleteExceptionsPayload,
  PermissionHelperPayload,
  PermissionHelperRolesAndExceptionPayload,
  RolesAndPermissionsState,
  SelectedGroupsForVueTreeselect,
  UpdateExceptionPayload
} from './types/roles-and-permissions.types';

export const rolesAndPermissionsActions: {
  getModules(
    store: Store<RolesAndPermissionsState>,
    moduleName?: string
  ): Promise<void>;
  getPermissionsForResource(
    store: Store<RolesAndPermissionsState>,
    payload: PermissionHelperPayload
  ): Promise<void>;
  getRolesAndExceptionsForResource(
    store: Store<RolesAndPermissionsState>,
    payload: PermissionHelperPayload
  ): Promise<void>;
  addUsersToRole(
    store: Store<RolesAndPermissionsState>,
    data: { id: number; payload: AddUsersToRoleDto }
  ): Promise<void>;
  addGroupsToRole(
    store: Store<RolesAndPermissionsState>,
    data: { id: number; payload: AddGroupsToRoleDto }
  ): Promise<void>;
  getExceptionsByModule(
    store: Store<RolesAndPermissionsState>,
    moduleName: string
  ): Promise<void>;
  getResourceExceptionsByModule(
    store: Store<RolesAndPermissionsState>,
    payload: {
      moduleName: string;
      resourceId: string;
      instanceId?: string;
    }
  ): Promise<void>;
  getResourceInstancesExceptions(
    store: Store<RolesAndPermissionsState>,
    resourceId: string
  ): Promise<void>;
  getGroupExceptions(
    store: Store<RolesAndPermissionsState>,
    groupId: number
  ): Promise<void>;
  createException(
    store: Store<RolesAndPermissionsState>,
    payload: CreateExceptionsPayload
  ): Promise<void>;
  updateException(
    store: Store<RolesAndPermissionsState>,
    payload: UpdateExceptionPayload
  ): Promise<void>;
  deleteException(
    store: Store<RolesAndPermissionsState>,
    payload: DeleteExceptionsPayload
  ): Promise<void>;
  setSelectedGroupsForVueTreeselect(
    store: Store<RolesAndPermissionsState>,
    payload: SelectedGroupsForVueTreeselect[]
  ): void;
  unsetGroupsForVueTreeselect(store: Store<RolesAndPermissionsState>): void;
  getPermissionsForUsers(
    store: Store<RolesAndPermissionsState>,
    payload: GeneralQueryParams
  ): Promise<void>;
  getPermissionsForGroups(
    store: Store<RolesAndPermissionsState>,
    payload: GeneralQueryParams
  ): Promise<void>;
  setCurrentEntityInView(
    store: Store<RolesAndPermissionsState>,
    payload: string
  ): Promise<void>;
  unsetCurrentEntityInView(
    store: Store<RolesAndPermissionsState>
  ): Promise<void>;
  getAccessConditionsForEntity(
    store: Store<RolesAndPermissionsState>,
    payload: {
      moduleName: string;
      entityId: string;
    }
  ): Promise<void>;
  getFilteredPermissionsGroupByUserId(
    store: Store<RolesAndPermissionsState>,
    userId: number
  ): Promise<void>;
} = {
  async getModules(
    store: Store<RolesAndPermissionsState>,
    moduleName?: string
  ): Promise<void> {
    try {
      store.commit('GET_MODULES_LOADING');
      const { data } = await rolesAndPermissionsApi.getModules(moduleName);
      store.commit('GET_MODULES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_MODULES_ERROR', error);
    }
  },

  async getPermissionsForResource(
    store: Store<RolesAndPermissionsState>,
    payload: PermissionHelperPayload
  ): Promise<void> {
    try {
      store.commit('GET_PERMISSIONS_FOR_RESOURCE_LOADING');
      const {
        data
      } = await rolesAndPermissionsApi.getPermissionsForResourceByModule(
        payload
      );
      store.commit('GET_PERMISSIONS_FOR_RESOURCE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PERMISSIONS_FOR_RESOURCE_ERROR', error);
    }
  },

  async getRolesAndExceptionsForResource(
    store: Store<RolesAndPermissionsState>,
    payload: PermissionHelperRolesAndExceptionPayload
  ): Promise<void> {
    try {
      store.commit('GET_ROLES_AND_EXCEPTIONS_FOR_RESOURCE_LOADING');
      const {
        data
      } = await rolesAndPermissionsApi.getRolesAndExceptionsForResourceByModule(
        payload
      );
      store.commit('GET_ROLES_AND_EXCEPTIONS_FOR_RESOURCE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ROLES_AND_EXCEPTIONS_FOR_RESOURCE_ERROR', error);
    }
  },

  async addUsersToRole(
    store: Store<RolesAndPermissionsState>,
    params: { id: number; payload: AddUsersToRoleDto }
  ): Promise<void> {
    try {
      store.commit('ADD_USERS_TO_ROLE_LOADING');
      const { data } = await rolesAndPermissionsApi.addUsersToRole(
        params.id,
        params.payload
      );
      store.commit('ADD_USERS_TO_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('ADD_USERS_TO_ROLE_ERROR', error.response.data.message);
    }
  },

  async addGroupsToRole(
    store: Store<RolesAndPermissionsState>,
    params: { id: number; payload: AddGroupsToRoleDto }
  ): Promise<void> {
    try {
      store.commit('ADD_GROUPS_TO_ROLE_LOADING');
      const { data } = await rolesAndPermissionsApi.addGroupsToRole(
        params.id,
        params.payload
      );
      store.commit('ADD_GROUPS_TO_ROLE_SUCCESS', data);
    } catch (error) {
      store.commit('ADD_GROUPS_TO_ROLE_ERROR', error.response.data.message);
    }
  },

  async getExceptionsByModule(
    store: Store<RolesAndPermissionsState>,
    moduleName: string
  ): Promise<void> {
    try {
      store.commit('GET_EXCEPTIONS_BY_MODULE_LOADING');
      const { data } = await rolesAndPermissionsApi.getExceptionsByModule(
        moduleName
      );
      store.commit('GET_EXCEPTIONS_BY_MODULE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_EXCEPTIONS_BY_MODULE_ERROR', error);
    }
  },

  async getResourceExceptionsByModule(
    store: Store<RolesAndPermissionsState>,
    payload: {
      moduleName: string;
      resourceId: string;
      instanceId: string;
    }
  ): Promise<void> {
    try {
      store.commit('GET_RESOURCE_EXCEPTIONS_BY_MODULE_LOADING');
      const {
        data
      } = await rolesAndPermissionsApi.getResourceExceptionsByModule(payload);
      store.commit('GET_RESOURCE_EXCEPTIONS_BY_MODULE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_RESOURCE_EXCEPTIONS_BY_MODULE_ERROR', error);
    }
  },

  async getResourceInstancesExceptions(
    store: Store<RolesAndPermissionsState>,
    resourceId: string
  ): Promise<void> {
    try {
      store.commit('GET_RESOURCE_INSTANCES_EXCEPTIONS_LOADING');
      const {
        data
      } = await rolesAndPermissionsApi.getResourceInstancesExceptions(
        resourceId
      );
      store.commit('GET_RESOURCE_INSTANCES_EXCEPTIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_RESOURCE_INSTANCES_EXCEPTIONS_ERROR', error);
    }
  },

  async getGroupExceptions(
    store: Store<RolesAndPermissionsState>,
    groupId: number
  ): Promise<void> {
    try {
      store.commit('GET_GROUP_EXCEPTIONS_LOADING');
      const { data } = await rolesAndPermissionsApi.getGroupExceptions(groupId);
      store.commit('GET_GROUP_EXCEPTIONS_SUCCESS', data[0]);
    } catch (error) {
      store.commit('GET_GROUP_EXCEPTIONS_ERROR', error);
    }
  },

  async createException(
    store: Store<RolesAndPermissionsState>,
    payload: CreateExceptionsPayload
  ): Promise<void> {
    try {
      store.commit('CREATE_EXCEPTION_LOADING');
      const { data } = await rolesAndPermissionsApi.createException(payload);
      store.commit('CREATE_EXCEPTION_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_EXCEPTION_ERROR', error);
    }
  },

  async updateException(
    store: Store<RolesAndPermissionsState>,
    payload: UpdateExceptionPayload
  ): Promise<void> {
    try {
      store.commit('UPADTE_EXCEPTION_LOADING');
      const { data } = await rolesAndPermissionsApi.updateException(payload);
      store.commit('UPADTE_EXCEPTION_SUCCESS', data);
    } catch (error) {
      store.commit('UPADTE_EXCEPTION_ERROR', error);
    }
  },

  async deleteException(
    store: Store<RolesAndPermissionsState>,
    payload: DeleteExceptionsPayload
  ): Promise<void> {
    try {
      store.commit('DELETE_EXCEPTION_LOADING');
      const { data } = await rolesAndPermissionsApi.deleteException(payload);
      store.commit('DELETE_EXCEPTION_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_EXCEPTION_ERROR', error);
    }
  },

  setSelectedGroupsForVueTreeselect(
    store: Store<RolesAndPermissionsState>,
    payload: SelectedGroupsForVueTreeselect[]
  ) {
    store.commit('SET_SELECTED_GROUPS_FOR_VUE_TREESELECT', payload);
  },

  unsetGroupsForVueTreeselect(store: Store<RolesAndPermissionsState>) {
    store.commit('UNSET_SELECTED_GROUPS_FOR_VUE_TREESELECT', undefined);
  },

  async getPermissionsForUsers(
    store: Store<RolesAndPermissionsState>,
    payload: GeneralQueryParams
  ): Promise<void> {
    try {
      store.commit('GET_PERMISSIONS_FOR_USERS_LOADING', payload);
      const { data } = await rolesAndPermissionsApi.getPermissionsForUsers(
        payload
      );
      store.commit('GET_PERMISSIONS_FOR_USERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PERMISSIONS_FOR_USERS_ERROR', error);
    }
  },

  async getPermissionsForGroups(
    store: Store<RolesAndPermissionsState>,
    payload: GeneralQueryParams
  ): Promise<void> {
    try {
      store.commit('GET_PERMISSIONS_FOR_GROUPS_LOADING', payload);
      const { data } = await rolesAndPermissionsApi.getPermissionsForGroups(
        payload
      );
      store.commit('GET_PERMISSIONS_FOR_GROUPS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_PERMISSIONS_FOR_GROUPS_ERROR', error);
    }
  },

  async setCurrentEntityInView(
    store: Store<RolesAndPermissionsState>,
    payload: string
  ): Promise<void> {
    try {
      store.commit('SET_CURRENT_ENTITY_IN_VIEW', payload);
    } catch (error) {
      store.commit('SET_CURRENT_ENTITY_IN_VIEW_ERROR', error);
    }
  },

  async unsetCurrentEntityInView(store: Store<RolesAndPermissionsState>) {
    try {
      store.commit('SET_CURRENT_ENTITY_IN_VIEW_ERROR', undefined);
    } catch (error) {
      store.commit('SET_CURRENT_ENTITY_IN_VIEW_ERROR', error);
    }
  },

  async getAccessConditionsForEntity(
    store: Store<RolesAndPermissionsState>,
    payload: { moduleName: string; entityId: string }
  ) {
    try {
      store.commit('GET_ACCESS_CONDITIONS_FOR_ENTITY_LOADING', payload);
      const {
        data
      } = await rolesAndPermissionsApi.getAccessConditionsForEntity(
        payload.moduleName,
        payload.entityId
      );
      store.commit('GET_ACCESS_CONDITIONS_FOR_ENTITY_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ACCESS_CONDITIONS_FOR_ENTITY_ERROR', error);
    }
  },

  async getFilteredPermissionsGroupByUserId(
    store: Store<RolesAndPermissionsState>,
    userId: number
  ): Promise<void> {
    try {
      store.commit('GET_FILTERED_PERMISSIONS_GROUP_BY_USER_ID_LOADING');
      const {
        data
      } = await rolesAndPermissionsApi.getFilteredPermissionsGroupByUserId(
        userId
      );
      store.commit('GET_FILTERED_PERMISSIONS_GROUP_BY_USER_ID_SUCCESS', data);
    } catch (error) {
      store.commit('GET_FILTERED_PERMISSIONS_GROUP_BY_USER_ID_ERROR', error);
    }
  }
};
